var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "offlineTrainBox",
      staticClass: "container-wrap offline-train-box training",
      attrs: { id: "tibi-play-page-wrapper" }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "father-portal",
              rawName: "v-father-portal",
              value: _vm.getParentMountedDom(),
              expression: "getParentMountedDom()"
            }
          ],
          staticClass: "left-side sider-opertion",
          class: {
            "sider-opertion-open": _vm.isPut,
            "sider-opertion-put": _vm.isOpen,
            "meeting-side": _vm.isOfflineMeeting
          },
          style: _vm.enlargeQR_div ? "width: 480px" : "width: 360px"
        },
        [
          _c("div", { staticClass: "put", on: { click: _vm.putMenu } }, [
            _vm._v(_vm._s(_vm.isPut ? "收起" : "展开"))
          ]),
          _vm.isFullScreenType
            ? _c(
                "div",
                { staticClass: "btns" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.toSign(0)
                        }
                      }
                    },
                    [_vm._v("电脑签到 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.toSign(1)
                        }
                      }
                    },
                    [_vm._v("电脑抽验 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.toSign(2)
                        }
                      }
                    },
                    [_vm._v("电脑签退 ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isOfflineMeeting && !_vm.isFullScreenType
            ? _c("div", { staticClass: "meeting-title" }, [
                _vm._v(_vm._s(_vm.offlineRecordData.planName))
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "list-wrap" },
            [
              _c(
                "ul",
                { staticClass: "tabs-wap", attrs: { id: "step221" } },
                _vm._l(_vm.tabList, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: { active: item.tabId === _vm.activeTab },
                      on: {
                        click: function($event) {
                          return _vm.changeTab(1, item.tabId)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.tabName) + " ")]
                  )
                }),
                0
              ),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab == 0,
                      expression: "activeTab == 0"
                    }
                  ],
                  staticClass: "course-list-cont"
                },
                [
                  _vm.isOfflineMeeting
                    ? _c("div", { staticClass: "m-meeting-box" }, [
                        _c(
                          "div",
                          { staticClass: "bd-operation-group" },
                          [
                            _c(
                              "el-button",
                              {
                                class:
                                  _vm.meetingObject.selectType == 0
                                    ? "checked"
                                    : "",
                                attrs: { round: "", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.onClickMeetingContentSelect(0)
                                  }
                                }
                              },
                              [_vm._v("会议简介")]
                            ),
                            _c(
                              "el-button",
                              {
                                class:
                                  _vm.meetingObject.selectType == 1
                                    ? "checked"
                                    : "",
                                attrs: { round: "", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.onClickMeetingContentSelect(1)
                                  }
                                }
                              },
                              [_vm._v("会议视频")]
                            ),
                            _c(
                              "el-button",
                              {
                                class:
                                  _vm.meetingObject.selectType == 2
                                    ? "checked"
                                    : "",
                                attrs: { round: "", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.onClickMeetingContentSelect(2)
                                  }
                                }
                              },
                              [_vm._v("会议文档")]
                            ),
                            _c(
                              "el-button",
                              {
                                class:
                                  _vm.meetingObject.selectType == 3
                                    ? "checked"
                                    : "",
                                attrs: { round: "", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.onClickMeetingContentSelect(3)
                                  }
                                }
                              },
                              [_vm._v("图片")]
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "bd-list-group" }, [
                          _vm.meetingObject.selectType == 0 &&
                          _vm.offlineRecordData.planDto
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.offlineRecordData.planDto
                                          .introduction
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.meetingObject.selectType == 1
                            ? _c(
                                "div",
                                [
                                  _vm.offlineRecordData.courseDtoList &&
                                  _vm.offlineRecordData.courseDtoList.length > 0
                                    ? _vm._l(
                                        _vm.offlineRecordData.courseDtoList,
                                        function(item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "cell-item",
                                              class: item.checked
                                                ? "cell-item-checked"
                                                : "",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onNodeClickEvent(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../../assets/img/meeting/meeting_video_icon.png")
                                                }
                                              }),
                                              _c("span", [
                                                _vm._v(_vm._s(item.courseName))
                                              ])
                                            ]
                                          )
                                        }
                                      )
                                    : [_vm._v("暂无会议视频")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.meetingObject.selectType == 2
                            ? _c(
                                "div",
                                [
                                  _vm.offlineRecordData.docList &&
                                  _vm.offlineRecordData.docList.length > 0
                                    ? _vm._l(
                                        _vm.offlineRecordData.docList,
                                        function(item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "cell-item",
                                              class: item.checked
                                                ? "cell-item-checked"
                                                : "",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onNodeClickEvent(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.courseName))
                                              ])
                                            ]
                                          )
                                        }
                                      )
                                    : [_vm._v("暂无会议文档")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.meetingObject.selectType == 3
                            ? _c(
                                "div",
                                [
                                  _vm.offlineRecordData.picList &&
                                  _vm.offlineRecordData.picList.length > 0
                                    ? _vm._l(
                                        _vm.offlineRecordData.picList,
                                        function(item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "cell-item",
                                              class: item.checked
                                                ? "cell-item-checked"
                                                : "",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onNodeClickEvent(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.courseName))
                                              ])
                                            ]
                                          )
                                        }
                                      )
                                    : [_vm._v("暂无会议图片")]
                                ],
                                2
                              )
                            : _vm._e()
                        ])
                      ])
                    : _c("el-tree", {
                        ref: "courseTreeBox",
                        staticClass: "courseTreeBox course-tree-box",
                        attrs: {
                          data: _vm.offlineRecordData.courseDtoList,
                          "node-key": "courseId",
                          "default-expanded-keys": _vm.expandedCourseArr,
                          "default-expand-all": "",
                          props: _vm.defaultProps,
                          "highlight-current": true,
                          "auto-expand-parent": true
                        },
                        on: { "node-click": _vm.onNodeClickEvent },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function({ node, data }) {
                              return _c(
                                "div",
                                { staticClass: "custom-tree-node-box" },
                                [
                                  data.selected === true
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "custom-tree-node-selected"
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(data.courseName))
                                            ])
                                          ]
                                        )
                                      ])
                                    : _c("span", [
                                        data.isCourseFinished === 2
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "custom-tree-node-finished"
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(data.courseName)
                                                  )
                                                ])
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass: "custom-tree-node"
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(data.courseName)
                                                  )
                                                ])
                                              ]
                                            )
                                      ])
                                ]
                              )
                            }
                          }
                        ])
                      })
                ],
                1
              ),
              _c(
                "el-scrollbar",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab !== 0,
                      expression: "activeTab !== 0"
                    }
                  ],
                  staticStyle: { height: "100%" }
                },
                [
                  _c("div", { staticClass: "sign-list-wrap" }, [
                    _vm.signType.phoneSign === 0
                      ? _c("div", { staticClass: "qrcode-box" }, [
                          _vm.signType.phoneSignOpenArr.includes(
                            _vm.activeTab
                          ) && !_vm.enlargeQR_div
                            ? _c(
                                "div",
                                {
                                  staticClass: "qrcode-wrap",
                                  attrs: { id: "step222" }
                                },
                                [
                                  _c("QrCodeLogo", {
                                    ref: "qrCodeLogoBox",
                                    attrs: {
                                      content: _vm.qrCodeContent,
                                      logoUrl: _vm.logoImg,
                                      opts: _vm.opts
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "qrcode-optering" },
                                    [
                                      _c("div", { staticClass: "qrcode-img" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "qrcode_button",
                                            on: { click: _vm.closeQrcodeSign }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "img-close",
                                              attrs: {
                                                src: _vm.closeQRImg,
                                                alt: "关闭"
                                              }
                                            }),
                                            _vm._v("关闭")
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "qrcode_button",
                                            on: { click: _vm.enlargeQrcodeSign }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "img-enlarge",
                                              attrs: {
                                                src: _vm.enlargeQRImg,
                                                alt: "放大"
                                              }
                                            }),
                                            _vm._v("放大 ")
                                          ]
                                        )
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.signType.signTypeName) +
                                            "码"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.signType.phoneSignOpenArr.includes(_vm.activeTab)
                            ? _c(
                                "div",
                                { staticClass: "qrcode-close" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.closeQrcodeSign }
                                    },
                                    [
                                      _vm._v(
                                        " 开启" +
                                          _vm._s(_vm.signType.signTypeName) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm.activeTab === 2
                      ? _c("div", { staticClass: "sign-type-title" }, [
                          _vm._v(
                            "待" + _vm._s(_vm.signType.signTypeName) + "列表"
                          )
                        ])
                      : _c("div", { staticClass: "sign-type-title" }, [
                          _vm._v(
                            "已" + _vm._s(_vm.signType.signTypeName) + "列表"
                          )
                        ]),
                    _vm.signLogList.list.length === 0
                      ? _c("div", { staticClass: "no-data-box" }, [
                          _vm._v(
                            "暂时没有" + _vm._s(_vm.activeTabName) + "数据"
                          )
                        ])
                      : _c(
                          "div",
                          [
                            _c(
                              "ul",
                              { staticClass: "sign-list" },
                              _vm._l(_vm.signLogList.list, function(
                                item,
                                index
                              ) {
                                return _c("li", { key: index }, [
                                  _c(
                                    "div",
                                    { staticClass: "top-info" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "name",
                                          attrs: { title: item.identityName }
                                        },
                                        [_vm._v(_vm._s(item.realName))]
                                      ),
                                      _c("div", { staticClass: "idcard" }, [
                                        _vm._v(_vm._s(item.cardNo))
                                      ]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "light",
                                            content: _vm.getMouseHover(item),
                                            placement: "right-start"
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "type" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("signSourceFilter")(
                                                    item.signSource
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm._f("resultTypeFilter")(
                                                    item.resultType
                                                  )
                                                ) +
                                                _vm._s(
                                                  _vm.signType.signTypeName
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            ),
                            _c("el-pagination", {
                              staticClass: "pagination-wrap",
                              attrs: {
                                "page-size": _vm.studentListParams.pageSize,
                                "current-page":
                                  _vm.studentListParams.currentPage,
                                small: "",
                                layout: "prev, pager, next",
                                total: _vm.signLogList.total
                              },
                              on: {
                                "current-change": _vm.monitorCurrentPageEvent
                              }
                            })
                          ],
                          1
                        )
                  ])
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "right", attrs: { id: "right-page" } },
        [
          _vm.isOfflineMeeting
            ? _c("div", { staticClass: "meeting-time" }, [
                _vm._v(_vm._s(_vm.clockTime))
              ])
            : _vm._e(),
          _c("div", { staticClass: "total" }, [
            _c("div", { staticClass: "left-side" }, [
              _c(
                "div",
                { staticClass: "btns", attrs: { id: "step223" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.toSign(0)
                        }
                      }
                    },
                    [_vm._v("电脑签到 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.toSign(1)
                        }
                      }
                    },
                    [_vm._v("电脑抽验 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.toSign(2)
                        }
                      }
                    },
                    [_vm._v("电脑签退 ")]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "total-left" }, [
              _c("span", [
                _vm._v(
                  "已签到" +
                    _vm._s(_vm.signLogList.signInNumber) +
                    "人，" +
                    _vm._s(_vm.isOfflineMeeting ? "会议" : "计划") +
                    "总人数: " +
                    _vm._s(
                      _vm.offlineRecordData.planDto
                        ? _vm.offlineRecordData.planDto.userCount
                        : 0
                    ) +
                    "人， 本次" +
                    _vm._s(_vm.isOfflineMeeting ? "会议" : "学习") +
                    "开始时间: " +
                    _vm._s(_vm.offlineRecordData.startTime)
                )
              ])
            ]),
            _c("div", { staticClass: "total-right" }, [
              _vm.pingObject.disConnected
                ? _c("span", { on: { click: _vm.onPingConnectEvent } }, [
                    _vm._v(_vm._s(_vm.pingObject.connectedMessage))
                  ])
                : _c(
                    "span",
                    {
                      staticStyle: {
                        color: "rgba(71,188,155,1)",
                        "font-size": "16px"
                      }
                    },
                    [_vm._v("当前网络:良好")]
                  )
            ])
          ]),
          _c("div", { staticClass: "content-main" }, [
            _c("div", { staticClass: "video-box", attrs: { id: "videoBox" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "dom-portal",
                      rawName: "v-dom-portal",
                      value: "#player-container-id",
                      expression: "'#player-container-id'"
                    }
                  ],
                  staticClass: "video-header-box",
                  staticStyle: {
                    "z-index": "1",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%"
                  }
                },
                [
                  !_vm.isOfflineMeeting
                    ? _c("div", { staticClass: "video-title" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.playCourse.courseName || "请选择课件") +
                            " "
                        )
                      ])
                    : _vm._e(),
                  !_vm.isOfflineMeeting
                    ? _c("div", { staticClass: "study-timmer-box" }, [
                        _vm._v(_vm._s(_vm.clockTime))
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                { ref: "videoWrapper", staticClass: "video-content-box" },
                [
                  _vm.playLoadedMetaDataFinish && _vm.isShowAudioSwitch
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "brother-portal",
                              rawName: "v-brother-portal",
                              value: ".vjs-fullscreen-control",
                              expression: "'.vjs-fullscreen-control'"
                            }
                          ],
                          staticClass:
                            "vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-audio-switch"
                        },
                        [
                          _c("el-switch", {
                            staticClass: "audio-switch",
                            attrs: { "inactive-text": "语音提醒" },
                            on: { change: _vm.monitorAudioSwitch },
                            model: {
                              value: _vm.isOpenAudioTip,
                              callback: function($$v) {
                                _vm.isOpenAudioTip = $$v
                              },
                              expression: "isOpenAudioTip"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.playLoadedMetaDataFinish
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "brother-portal",
                              rawName: "v-brother-portal",
                              value: ".vjs-fullscreen-control",
                              expression: "'.vjs-fullscreen-control'"
                            }
                          ],
                          staticClass:
                            "vjs-menu-button vjs-menu-button-popup vjs-control vjs-button vjs-switch"
                        },
                        [
                          _c("el-switch", {
                            staticClass: "dan",
                            attrs: {
                              "active-text": "弹",
                              "inactive-text": "弹",
                              "inactive-color": "#333333"
                            },
                            on: {
                              change: function($event) {
                                return _vm.setBarrageIsShow(_vm.barrageIsShow)
                              }
                            },
                            model: {
                              value: _vm.barrageIsShow,
                              callback: function($$v) {
                                _vm.barrageIsShow = $$v
                              },
                              expression: "barrageIsShow"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("vue-baberrage", {
                    directives: [
                      {
                        name: "dom-portal",
                        rawName: "v-dom-portal",
                        value: "#player-container-id",
                        expression: "'#player-container-id'"
                      }
                    ],
                    ref: "vueBarrage",
                    class: {
                      "baber-rage-stage-group": true,
                      "meeting-baber-rage-stage-group": _vm.isOfflineMeeting
                    },
                    staticStyle: { "z-index": "0" },
                    attrs: {
                      isShow: _vm.barrageIsShow,
                      lanesCount: _vm.lanesCount,
                      hoverLanePause: true,
                      barrageList: _vm.barrageList,
                      loop: _vm.barrageLoop
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.playVideo.apply(null, arguments)
                      }
                    }
                  }),
                  _vm.enlargeQR
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "dom-portal",
                              rawName: "v-dom-portal",
                              value: _vm.getParentMountedDom(),
                              expression: "getParentMountedDom()"
                            }
                          ],
                          staticClass: "scale-qr-box"
                        },
                        [
                          _vm.signType.phoneSignOpenArr.includes(
                            _vm.activeTab
                          ) && _vm.enlargeQR_div
                            ? _c(
                                "div",
                                {
                                  ref: "enlargeQR",
                                  staticClass:
                                    "qrcode-wrap qrcode-wrap-enlargeQR",
                                  staticStyle: { transform: "scale(1.5)" },
                                  on: {
                                    mousewheel: function($event) {
                                      return _vm.onmousewheelQrCode($event)
                                    },
                                    mouseover: _vm.scrollbarStop,
                                    mouseleave: _vm.scrollbarAction
                                  }
                                },
                                [
                                  _c("QrCodeLogo", {
                                    ref: "qrCodeLogoBox",
                                    attrs: {
                                      content: _vm.qrCodeContent,
                                      logoUrl: _vm.logoImg,
                                      opts: _vm.opts
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "qrcode-optering" },
                                    [
                                      _c("div", { staticClass: "qrcode-img" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "qrcode_button",
                                            on: {
                                              click: _vm.closeQrcodeSign_enlarge
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "img-close",
                                              attrs: {
                                                src: _vm.closeQRImg,
                                                alt: "关闭"
                                              }
                                            }),
                                            _vm._v("关闭 ")
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "qrcode_button",
                                            on: {
                                              click:
                                                _vm.narrowQrcodeSign_enlarge
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "img-enlarge",
                                              attrs: {
                                                src: _vm.shrinkQRImg,
                                                alt: "缩小"
                                              }
                                            }),
                                            _vm._v("缩小 ")
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "qrcode_button",
                                            on: {
                                              click:
                                                _vm.enlargeQrcodeSign_enlarge
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "img-enlarge",
                                              attrs: {
                                                src: _vm.enlargeQRImg,
                                                alt: "放大"
                                              }
                                            }),
                                            _vm._v("放大 ")
                                          ]
                                        )
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.signType.signTypeName) +
                                            "码"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _c("TibiTcplayer", {
                    ref: "player",
                    class: _vm.isOfflineMeeting ? "meeting-tcp-player" : "",
                    attrs: {
                      id: "videoBarrage",
                      width: _vm.videoObj.width,
                      height: _vm.videoObj.height,
                      options: _vm.videoObj.options,
                      autoplay: _vm.videoObj.autoplay,
                      volume: _vm.videoObj.volume,
                      muted: false
                    },
                    on: { playerTarget: _vm.watchPlayer }
                  })
                ],
                1
              ),
              _vm.dialogCenterVisible
                ? _c(
                    "div",
                    {
                      staticClass: "video-play-gary-bg",
                      style: { height: _vm.videoObj.height }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alert-center-wrap" },
                        [
                          _c("p", { staticClass: "title-alert" }, [
                            _vm._v("温馨提示")
                          ]),
                          _c("p", { staticClass: "detail-alert" }, [
                            _vm._v("已暂停播放，请勿将鼠标移出播放窗口！")
                          ]),
                          _c(
                            "el-button",
                            {
                              staticClass: "button-alert",
                              attrs: { type: "primary" },
                              on: { click: _vm.handleDialogCenterVisible }
                            },
                            [_vm._v("确 定 ")]
                          ),
                          _c("p", { staticClass: "clear-alert" })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "alert-center-dis-wrap" })
                    ]
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.isOfflineMeeting &&
                      _vm.meetingObject.selectType == 0 &&
                      _vm.offlineRecordData.planDto,
                    expression:
                      "isOfflineMeeting && meetingObject.selectType == 0 && offlineRecordData.planDto"
                  }
                ],
                staticClass: "info-box"
              },
              [
                _c("div", { staticClass: "info-box-bd" }, [
                  _vm._v(_vm._s(_vm.offlineRecordData.planDto.introduction))
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.isOfflineMeeting &&
                      _vm.meetingObject.selectType == 2 &&
                      _vm.playCourse,
                    expression:
                      "isOfflineMeeting && meetingObject.selectType == 2 && playCourse"
                  }
                ],
                staticClass: "doc-box"
              },
              [_c("PdfPreview", { attrs: { url: _vm.playCourse.fileUrl } })],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.isOfflineMeeting &&
                      _vm.meetingObject.selectType == 3 &&
                      _vm.playCourse,
                    expression:
                      "isOfflineMeeting && meetingObject.selectType == 3 && playCourse"
                  }
                ],
                staticClass: "photo-box"
              },
              [_c("img", { attrs: { src: _vm.playCourse.fileUrl } })]
            )
          ]),
          _c("TrainingRecordsTable", {
            staticStyle: { "margin-bottom": "20px", "margin-top": "20px" },
            attrs: {
              propsStudentListParams: _vm.studentListParams,
              supportSignOut: true,
              id: "step224"
            }
          })
        ],
        1
      ),
      _c("BaseDialog", {
        attrs: {
          isVisible: _vm.dialogCommonObject.isVisible,
          tag: _vm.dialogCommonObject.tag,
          content: _vm.dialogCommonObject.content,
          ensureButtonTitle: _vm.dialogCommonObject.ensureButtonTitle,
          cancelButtonTitle: _vm.dialogCommonObject.cancelButtonTitle,
          isContianerCancel: _vm.dialogCommonObject.isContianerCancel,
          supportMaskClick: _vm.dialogCommonObject.supportMaskClick
        },
        on: {
          "update:isVisible": function($event) {
            return _vm.$set(_vm.dialogCommonObject, "isVisible", $event)
          },
          "update:is-visible": function($event) {
            return _vm.$set(_vm.dialogCommonObject, "isVisible", $event)
          },
          "call-back": _vm.onDialogCommonCallBackEvent
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }