import planApi from "../../api/planApi";
import { Constants } from "../../util/Constants";
import { HudGlobal } from "../../util/HudGlobal";
import hoursApi from "../../api/hours/hoursApi";
import TibiTcplayer from "@tibi/tcplayer";
import socketjs from "@tibi/socket";
import QrCodeLogo from "../../components/vue-qrcode/QRCode.vue";
import playerBg from "../../assets/img/player-bg.png";
import storeDBManger from "../../util/StoreDBManger";
import OfflineParam from "../../util/OfflineParam";
import Vue from "vue";
import StoreTrainDBManger from "../../db/StoreTrainDBManger";
import HomeGuideSteps from "@/util/guide-step/HomeGuideSteps";
import GuideStepHelper from "@/util/guide-step/GuideStepHelper";
import ConstantsGuideCode from "@/util/constants/ConstantsGuideCode";
import TBDriver from "@tibi/vue-driver/TBDriver.js";
import BaseDialog from "../../components/base-dialog/BaseDialog.vue";
import Table from "./components/table/index.vue";
import SocketHeart from "../../util/SocketHeart";
import StoreDBManger from "../../util/StoreDBManger";
import videoBaberrage from "./mixins/videoBaberrage";
import qrCode from "./mixins/qrCode";
import SettingModal from "./components/setting-modal/SettingModal.vue";
import TrainingRecordsTable from "../training-records/training-records-table/TrainingRecordsTable.vue";
import filters from "../../filters/filters";
import TrackConstants from "../../util/constants/TrackConstants";
import TibiMatomoManger from "../../util/manager/TibiMatomoManger";
import { mapState } from "vuex";
import GlobalHelper from "../../util/GlobalHelper.js";
import SpeechHelper from "../../util/helper/SpeechHelper";
import StudyVideoTrain from "./mixins/StudyVideoTrain";
import PdfPreview from "./components/pdf-preview/PdfPreview.vue";
import { gotoHomeList } from "../middle-ware/utils/ControlRouter";
import { getCurrentSystemType, getCurrentUserId } from "../../util/user/UserManger";
import { getOfflineRecordIdFromDB, setOfflineRecordIdToDB } from "../../util/db/TrainDBManger";
import { COMMON } from "../../util/constants/RouteConstant";

// 新增弹幕功能的js代码放在了mixins目录下的video-baberrage
// 二维码新改动js代码放在了mixins目录下的

export default {
    name: "PrepareTraining",
    components: {
        TibiTcplayer,
        QrCodeLogo,
        BaseDialog,
        Table,
        SettingModal,
        TrainingRecordsTable,
        PdfPreview,
    },
    mixins: [videoBaberrage, qrCode, StudyVideoTrain],
    data() {
        return {
            // lzb 新增key
            keys: {
                // 计时
                STUDY_OFFLINE: "study_offline",
            },
            activeTab: 0,
            activeTabName: "",
            // 左侧目录操作, 课程0, 签到1, 抽验2, 签退3
            tabList: [],

            // 培训基本信息, 含基本信息, 计划基本信息, 培训管理人员, 课件树
            offlineRecordData: {
                planDto: {
                    // 初始化计划的总人数显示
                    userCount: 0,
                },
                courseDtoList: [],

                // 上次播放到的id
                lastFileId: null,
                // 计时器完成学时总时长
                finishedTotalHours: 0,
            },
            // 课件播放数据
            courseArrayList: [],

            // 视频播放参数
            videoObj: {
                width: "100%",
                height: "600px",
                options: {
                    appID: "1256136530",
                    fileID: "",
                    // 控制是否显示进度条
                    progressControl: false,
                },
                autoplay: false,
                volume: 70,
                // allowDrag: false,
            },
            // 视频播放参数
            videoParamter: {
                pause: false,
            },

            // 展开树结果
            expandedCourseArr: [],
            // 课件树形格式
            defaultProps: {
                children: "children",
                label: "courseName",
                key: "courseId",
            },
            // 学员签到等参数
            studentListParams: {
                currentPage: 1,
                pageSize: 10,
                pagerCount: 5,
                // 岗位编码
                dutyCode: "",
                userId: getCurrentUserId(),
                // 计划ID
                planId: 1,
                // 搜索,关键字检索（姓名、手机号码、身份证号）
                searchVal: "",
                // 签到类型（0:待签到，10：已签到，20：抽验，30：签退）
                signType: 0,
                // 现场学习培训记录ID
                offlineRecordId: "",
                // 现场学习教室id
                classroomId: "",
                identityCode: Constants.organizeIndentityType.INDENTITY_STUDENT,
            },
            // 已签到/抽验/签退记录的记录
            signLogList: {
                validateNumber: "",
                // 签到人数量
                signInNumber: 0,
                // 余下数量
                signInRestNumber: 0,
                // 所有人员签到记录
                list: [],
                total: 0,
            },
            // 签到/抽验/签退方式, 只能选择一种,PC或手机二维码
            signType: {
                // 是否开始播放页面手机签到, 不开启全部都回签到/抽验/签退界面进行 0开启, 1不开启
                phoneSign: 0,
                // 二维码开启的坐标, 根据Tab index决定
                phoneSignOpenArr: [1, 2, 3],
                phoneSignIn: 0,
                phoneSignCheck: 0,
                phoneSignOut: 0,
                signTypeName: "签到",
            },
            // 二维码内容
            qrCodeContent: "",
            // 二维码LOGO
            logoImg: "",
            // 二维码配置项
            opts: {
                errorCorrectionLevel: "H",
                type: "image/jpeg",
                rendererOpts: { quality: 0.8 },
                width: 244,
            },
            // 手机二维码签到
            qrcodeSignIn: {
                // 被打开的二维类别, 1签到 2抽验, 3签退
                openSignQrcode: [],
                // 请求签到二维码的参数, 被通用请求代替
                params: {
                    userId: this.$store.getters.userId,
                    // 签到来源（10：pc,20：Android，21：iOS，22：公众号，23小程序)
                    signSource: 10,
                    // 学习场景（10：pc网络，14：现场考试，15：pc现场学习，20：Android，21：iOS，22：公众号，23：小程序）
                    sourceType: 15,
                    planId: "",
                    offlineRecordId: "",
                    classroomId: "",
                    /*
                    50：签到二维码通知开始，51：抽验二维码通知开始，52：签退二维码通知开始,53：
                    签到二维码结束通知，54：抽验二维码结束通知，55：签退二维码结束通知，56，二维码有效检测
                    */
                    operationType: 50,
                },
            },

            // 基础参数相关, 学时视频请求数据
            routerParam: {
                // trainingId: null,
                planId: null,
                // hoursRecordId: null,
                offlineRecordId: null,
                classroomId: null,
                // dutyCode: null,
                parentCourseId: null,
                currentCourseId: null,
                currentFileId: null,
                type: getCurrentSystemType(),
                // parameterMap: null
            },
            socketOnlineEvent: "training.online",
            socketMessageEvent: "training.online.message",
            socketHeartClient: "training.heart.client",
            // 计时器
            clockTime: "00:00:00",
            // 时钟计时器
            // clockTimer: null,
            clockTimerLock: false,
            // 视频提交计时器
            submitTimer: null,
            // 是否已经开始培训
            isStartToLean: false,
            // 播放器 lzb 2020-03-13
            playerObject: {
                // 播放器是否在播放状态 - 新增播放器状态判断播放器是否真实播放
                playing: false,
            },
            // 正在播放的课件内容
            playCourse: "",
            isFristEntry: true, // 是否是第一次进入页面
            playLoadedMetaDataFinish: false, // 播放器是否加载完成 lzb20200409 视频加载不报错
            isDialogVisible: false, //是否有弹框
            // 方法锁定不重复运行 - 退出到首页
            signOutToHomeListFun: false,
            //  // 切换页面锁 - 有可能出现多次
            changePageLock: false,
            // 页面参数控制 12个
            ctrlParameter: {
                // 学时提交间隔  默认60s
                studyCourseProgressCommitDuration: 60,
                // [需要提前]视频进度条是否可拖动 0(默认)不可拖动, 1可拖动
                videoProcessBarDragEnable: 0,
                // 是否可播放已完成学习的视频 0不能播放, 1(默认)能播放
                offlineVideoFinishedReplayEnable: 1,
                // 多个视频是否必须按顺序学习 0(默认)可随机学习, 1必须顺序学习
                videoPlayOrder: 0,
                // 是否自动播放下一个视频(PC端) 0不能播放, 1(默认)能播放
                videoPcAutoplayNext: 1,
                // 抽验是否强制跳转界面(0强制跳转(需要暂停计时) 1不强制(不暂停计时) )
                offlineSkipPlayerPageAuth: 0,
                // 是否允许播放界面抽验,（0（默认）可以 1不可以）
                offlinePlayerPageAuth: 0,
                // 是否允许播放界面签到（0（默认）可以 1不可以）
                offlinePlayerPageSignin: 0,
                // 是否允许播放界面签退（0（默认）可以 1不可以）
                offlinePlayerPageSignout: 0,
                // 是否暂停计时(视频暂停) 0不暂停计时, 1(默认)暂停计时
                videoStopTimerWithVideoPause: 1,
                // 是否暂停计时(焦点离开页面) 0不暂停计时, 1(默认)暂停计时
                videoStopTimerWithLeavePage: 1,
            },
            // 通用弹框
            dialogCommonObject: {
                isVisible: false,
                content: "",
                tag: 0,
                isContianerCancel: true,
                ensureButtonTitle: "确定",
                cancelButtonTitle: "取消",
                supportMaskClick: false, //不支持遮罩点击
            },

            // 心跳对象
            pingObject: {
                // 连接状态
                disConnected: false,
                disConnectedCount: 0, // 未连接或者连接超时次数
                disConnectedMaxCount: 2, // 超时最大次数
                connectedMessage: "您的网络已断开,点击重试",
                pingDialogIsVisible: false,
                offline: null, // 没有网络
                online: null, // 有网络
            },
            dialogCenterVisible: false, // 视频中心弹窗
            dialogCenterJustDisappear: false, // 视频中心弹窗刚刚消失
            isOpenAudioTip: true, // 是否打开语音提示, 默认打开
            isShowAudioSwitch: false, // 是否显示语言提示
        };
    },
    computed: {
        ...mapState({
            parameterCodeList: (state) => {
                return state.app.parameterCodeList;
            },
        }),
    },
    mounted() {
        console.log("video-training.js mounted.......");
        this.setLogoImg();
        let query = this.$route.query;
        // 现场培训记录ID
        let offlineRecordId;
        if (query.offlineRecordId) {
            offlineRecordId = query.offlineRecordId;
        } else {
            offlineRecordId = getOfflineRecordIdFromDB();
        }

        this.routerParam.offlineRecordId = offlineRecordId;
        this.routerParam.type = query.type;
        console.log("mounted rqm initPage");
        this.initPage();

        // 初始化配置参数
        const that = this;
        OfflineParam.getCurretPlanParamConfigFromDB(function(planParam) {
            that.setupInitPlanParam(planParam);

            // 离开页面清除计时等
            that.initVisibilityChange();
        });

        // setInterval(() => {
        //     that.addBaberRageList(that.getTestObj);
        // }, 100);

        // 默认参数
        this.isStartToLean = false;

        //  进入页面获取是暂停的
        this.videoParamter.pause = true;
        this.playLoadedMetaDataFinish = false;

        // 6-8 todo 叶炼修改 如果是签到进入，清空缓存值
        // let signType = window.localStorage.getItem("signType");
        let signType = StoreDBManger.getDataWithLocalStorage(StoreDBManger.storageKeys.SELECT_SIGN_TYPE);
        if (!signType || parseInt(signType) == 0) {
            const key = this.getTimerKey();
            // 先移除数据
            this.removeTimerKeyData();
            // 保存数据
            storeDBManger.saveDataToLocalStorage(key, 0);
        } else {
            //  抽验和签退-直接显示值 - lzb 2020-06-15 抽验签退回来之后显示值
            const key = this.getTimerKey();
            const timeStr = storeDBManger.getDataWithLocalStorage(key);
            if (timeStr) {
                const time = Number(timeStr);
                this.clockShow(time);
            }
        }

        // lzb -2020-08-27 抽验之后的引导
        if (signType == 1) {
            // 抽验
            this.checkSignSpotOperationGuide();
        }
    },
    beforeDestroy() {
        this.sendCourseProgress();
        this.removeVisibilityChange();
        this.stopClockTimer();
        this.closeSubmitVideoProgessTimer();
        this.playDestoryed();
        // lzb 2020-07-02 移除监听
        this.removeWindowListener();

        // 关闭弹框 -lzb20200410
        this.$message.closeAll();
        this.$message.close();
    },
    methods: {
        /**fbz 2021-07-28
         * @description: 系统参数配置二维码logo
         * @param {*}
         * @return {*}
         */
        setLogoImg() {
            const that = this;
            if (this.parameterCodeList.study_qrcode_logo) {
                const temp = JSON.parse(this.parameterCodeList.study_qrcode_logo);
                GlobalHelper.getBase64Image(temp[0].parameterName, (dataURL) => {
                    that.logoImg = dataURL;
                });
            }
        },
        /*
         * 页面创建时需要做的事
         * */
        initPage() {
            this.$message.closeAll();
            // 全局界面标识 plan prepare sign play
            window.localStorage.setItem("currentStep", "play");

            /*
                      OK 1.获取现场学习的基本信息
                      1.获取课程目录
                      OK 2.获取签到/抽验/签退数据
                      3.签到/抽验/签退交互
                      4.视频播放及控制
                      5.学时发起
                      6.抽验验证
                   */
            this.getOfflineRecordDataOne();

            // 初始化SOCKET连接, 根据用户
            this.initSocket();

            // 默认不播放
            this.playerObject.playing = false;
            // 默认没有弹框
            this.isDialogVisible = false;
        },
        beforeDestroy() {
            this.sendCourseProgress();
            this.removeVisibilityChange();
            this.stopClockTimer();
            this.closeSubmitVideoProgessTimer();
            this.playDestoryed();
            // 关闭弹框 -lzb20200410
            this.$message.closeAll();
        },
        /**
         * 初始化配置参数
         */
        setupInitPlanParam(planParam) {
            // 处理参数
            Object.assign(this.ctrlParameter, planParam);

            //  获取参数 - 是否显示语言
            this.isShowAudioSwitch = planParam.pcStudyValidateVoice == 1;
            this.isOpenAudioTip = planParam.isOpenStudyAudio;

            // 刷新左侧tab
            this.loadLeftHeaderTabData();
        },

        /**
         * @description: 监听语音的值
         * @param {*} val
         * @return {*}
         */
        monitorAudioSwitch() {
            OfflineParam.modifyOfflineParams(this.offlineRecordData.planId, {
                isOpenStudyAudio: this.isOpenAudioTip,
            });
        },
        /*
         * 初始化socket
         * */
        initSocket() {
            // 初始化
            let userId = this.$store.getters.userId;
            let url = process.env.VUE_APP_SOCKET_URL;
            socketjs.init({
                url: url,
                userId: userId,
                // 不默认连接
                createConnection: false,
            });

            const that = this;
            // lzb 2020-06-19 心跳机制
            SocketHeart.initOptions();
            SocketHeart.startPing(
                function() {
                    // 发送心跳
                    that.sendHeartPing();
                },
                function() {
                    // 超时
                    // 没有检查心跳
                    that.pingObject.disConnected = true;
                    that.pingObject.disConnectedCount = that.pingObject.disConnectedCount + 1;
                    console.log("超时未检测到心跳", that.pingObject.disConnectedCount);
                    that.handleNetworkTimeOutOffLine();
                }
            );

            this.pingObject.offline = function() {
                console.log("监听到网络已经断开-------");
                that.pingObject.disConnected = true;
                // HudGlobal.showWarningWithMessage('您当前网络不稳定，可能影响学时无法正常提交，请检查网络！');
            };
            this.pingObject.online = function() {
                console.log("监听到网络已经修复-------");
                that.pingObject.disConnected = false;
                that.onPingConnectEvent();
            };
            window.addEventListener("offline", this.pingObject.offline);
            window.addEventListener("online", this.pingObject.online);

            // 监听心跳返回
            that.initSocketHeartResponse();

            let _this = this;

            // 连接检查抽验 lzb- 2020-06-23
            socketjs.on("connect", function() {
                console.log("socket已连接-------");
                that.pingObject.disConnected = false;
                that.getCheckList();
            });

            socketjs.on("disconnect", function() {
                console.log("socket已断开-------");
                that.pingObject.disConnected = true;
                // 连接断开,
                // 停止视频播放
                _this.pause();
                // 停止计时器
                _this.stopClockTimer();
                _this.sendSocketPauseTimer();
                // 停止视频进度上传
                _this.closeSubmitVideoProgessTimer();
                // 关闭socket连接
                socketjs.close();
            });

            socketjs.on("reconnect", function() {
                   console.log("initSocket reconnect rqm initPage");
                _this.initPage();
            });

            this.initSocketResponseData();
            this.initSocketServerPushData();
        },

        // =================================================心跳 start=============================================//

        /**
         * 点击心跳重新连接
         */
        onPingConnectEvent() {
            TibiMatomoManger.sendVideoTrainingTrackEvent(TrackConstants.eventNameKeys.video_training_menu_connected_net);
            this.initSocket();
            // 如果是网络链接弹框 需要取消
            if (this.pingObject.pingDialogIsVisible && this.dialogCommonObject.tag === 999) {
                this.dialogCommonObject.isVisible = false;
            }
        },

        /**
         * 处理没有没有网络或者超时
         */
        handleNetworkTimeOutOffLine() {
            const that = this;
            that.pingObject.disConnected = true;
            this.$message.closeAll();
            this.$message.close();
            this.showFullScreenMessage("您当前网络不稳定，可能影响学时无法正常提交，请检查网络！", true);

            if (that.pingObject.disConnectedCount > that.pingObject.disConnectedMaxCount) {
                that.handleOffLinePingEvent();
                // 关闭心跳超时
                SocketHeart.stopPingTimeOutTimer();
                that.showSocketOffLineAlert();
                // 关闭socket连接
                socketjs.close();
            }
        },

        /**
         * socket断开连接踏勘
         */
        showSocketOffLineAlert(msg) {
            const that = this;
            if (this.pingObject.pingDialogIsVisible) {
                return;
            }

            let fullScreen = that.isFullScreen();
            if (fullScreen) {
                that.exitFullScreen();
            }
            this.pingObject.pingDialogIsVisible = true;
            const message = msg || "您当前网络较差，建议检查网络后重新连接或退出学习！";

            this.dialogCommonObject.isVisible = true;
            this.dialogCommonObject.content = message;
            this.dialogCommonObject.tag = 999;
            this.dialogCommonObject.isContianerCancel = true;
            this.dialogCommonObject.ensureButtonTitle = "重新连接";
            this.dialogCommonObject.cancelButtonTitle = "退出学习";
            this.dialogCommonObject.supportMaskClick = false;
        },

        /**
         * 发送心跳
         */
        sendHeartPing() {
            const params = {};
            params.userId = this.$store.getters.userId;
            params.sourceType = 15; // 现场学习
            socketjs.send(this.socketHeartClient, params);
            // console.log('定时发送心跳------',params);
        },
        /**
         * 监听心跳返回数据
         */
        initSocketHeartResponse() {
            const that = this;
            // 监听心跳
            socketjs.on(this.socketHeartClient, function(data) {
                // console.log('定时接收到心跳------',data);
                if (data && data.content && data.content.success) {
                    that.pingObject.disConnected = false;
                    that.pingObject.disConnectedCount = 0;
                    // 关闭心跳超时
                    SocketHeart.stopPingTimeOutTimer();
                } else {
                    // 没有检查心跳
                    that.pingObject.disConnectedCount = that.pingObject.disConnectedCount + 1;
                    that.pingObject.disConnected = true;
                    that.handleNetworkTimeOutOffLine();
                }
            });
        },
        /**
         * 处理未检测到心跳
         */
        handleOffLinePingEvent() {
            // 停止上传进度
            this.closeSubmitVideoProgessTimer();
            // 停止计时
            this.stopClockTimer();
            // 暂停播放
            this.pause();
        },

        // =================================================心跳 end=============================================//

        /*
         * socket请求返回数据
         * */
        initSocketResponseData() {
            let that = this;
            socketjs.on(this.socketOnlineEvent, function(data) {
                let success = data.content.success;
                if (success) {
                    let content = data.content;
                    let returnOfflineRecordId = content.offlineRecordId;
                    if (returnOfflineRecordId && returnOfflineRecordId !== that.getOfflineRecordId()) {
                        return false;
                    }

                    let operationType = content.data.operationType;
                    console.log("socketOnline类别返回---", operationType, "socketOnline数据", data);
                    // 获取视频进度
                    if (operationType === 12) {
                        that.currentVideoSecond = content.data.finishedCourseDuration;
                        that.setCurrentTime(that.currentVideoSecond);
                        // 视频没有加载成功 - lzb20200409
                        if (!that.playLoadedMetaDataFinish) {
                            that.onNodeClickEvent(that.playCourse);
                        }
                        // 如果是自动播放将 直接开始播放lzb -2020-04-16
                        if (that.isVideoPlayType) {
                            if (that.ctrlParameter.videoPcAutoplayNext == 1) {
                                that.startLearn();
                            }
                        } else {
                            that.startLearn();
                        }
                    }
                    // 上传视频进度
                    if (operationType === 14) {
                        //  console.log("上传视频进度成功------", operationType, data);
                    }
                    // 视频全部播放完 - 服务器自动停止计时
                    if (operationType === 15) {
                        // 如果不是视频播放
                        if (!that.isVideoPlayType) {
                            return false;
                        }
                        // 停止视频进度上传
                        that.closeSubmitVideoProgessTimer();
                        that.stopClockTimer();

                        //  2. 标记视频颜色
                        that.refreshFinishCourseList(that.playCourse.courseId);
                        if (data.content.code === 89998 && (this.isOfflineMeeting || that.ctrlParameter.offlineVideoFinishedReplayEnable == 1)) {
                            that.exitPlayerFullScreen();
                            // 最后一个视频的时候,弹窗提示是否重新播放
                            HudGlobal.showAlertConfirmMessages(`${data.content.description}`, "", "温馨提示", "重新播放").then(() => {
                                const firstCourse = that.courseArrayList[0];
                                that.onNodeClickEvent(firstCourse);
                            });
                            return false;
                        }
                        //  3. 检测自动加载下一个课件
                        that.autoPlayNextCourse();
                    }

                    // 开始学习
                    if (operationType === 20) {
                        that.isStartToLean = true;
                        let currentFinishedHours = content.data.currentFinishedHours;
                        if (currentFinishedHours !== undefined && currentFinishedHours !== null) {
                            const key = that.getTimerKey();
                            // 先移除数据
                            that.removeTimerKeyData();
                            storeDBManger.saveDataToLocalStorage(key, currentFinishedHours);
                        }

                        const isClock = content.data.isClock;
                        //console.log("isClock", isClock);
                        console.log("开始学习的的是否计时------isClock", isClock, currentFinishedHours);

                        that.startClockTimer(isClock);
                        // 开始播放
                        isClock && that.play();
                    }

                    if (operationType === 22) {
                        that.doUserSignDataList(content.data);
                    }
                    if (operationType === 24) {
                        console.info("抽验通知");
                    }
                    // 视频暂停
                    if (operationType === 25) {
                        // 停止计时
                        that.stopClockTimer();
                        that.closeSubmitVideoProgessTimer();
                        // 视频暂停
                        that.videoParamter.pause = true;
                    }
                    // 开始抽验, 返回抽验界面
                    if (operationType === 26) {
                        that.exitPlayerFullScreen();
                        // lzb
                        if (that.ctrlParameter.offlineSkipPlayerPageAuth === 0) {
                            // 强制
                            this.startForceSignAlert();
                        } else {
                            console.log("开始抽验...............但不强制跳转...");
                            // 不强制
                            that.showCheckTip();
                        }
                    }
                    if (operationType === 35) {
                        console.info("操作类型operationType=35, 恢复计时");
                        that.playerObject.playing = false;
                        that.videoParamter.pause = false;
                        if (content && content.code && content.code == 80113) {
                            that.exitPlayerFullScreen();
                            that.stopTimerAndRemote();
                            that.$alert("培训已被终止! 原因: " + content.description, "提示", {
                                confirmButtonText: "确定",
                                type: "warning",
                            }).then(() => {
                                let toHomeParam = {};
                                toHomeParam.userId = that.$store.getters.userId;
                                that.toHomeList(toHomeParam, true);
                            });
                        } else {
                            that.setCurrentTime();
                            const dataIsClock = content.data.isClock;
                            that.startClockTimer(dataIsClock);
                        }
                    }
                    if (operationType === 100) {
                        // this.$router.push({path: "/home/list"});
                        that.doUserSignDataList(content.data);
                    }
                } else {
                    console.log("socketOnline处理失败", data);
                    // lzb- 2020-0404- 处理主送发送回到
                    const content = data.content;
                    const code = content.code;
                    // 不能再播放啦80096
                    if (code == 80096) {
                        // 暂停播放器
                        that.pause();
                        // 暂停计时
                        that.stopClockTimer();
                        that.closeSubmitVideoProgessTimer();
                        that.exitPlayerFullScreen();
                        that.showFullScreenMessage(content.description, true);
                        // 其他设备计时
                    } else if (code == 80191) {
                        that.showFullScreenMessage(content.description, true);
                    } else if (code == 102) {
                        // 没有数据暂时不处理
                    } else {
                        HudGlobal.showErrorWithMessage(content.description);
                    }
                }
            });
        },
        /*
         * 服务端主动推送数据
         * */
        initSocketServerPushData() {
            let that = this;
            socketjs.on(this.socketMessageEvent, function(data) {
                let content = data.content.data || data.content;
                if (content) {
                    let returnOfflineRecordId = content.offlineRecordId;
                    if (returnOfflineRecordId && returnOfflineRecordId !== that.getOfflineRecordId()) {
                        return false;
                    }

                    let operationType = content.operationType;
                    if (operationType !== 50 && operationType !== 51 && operationType !== 52) {
                        console.log("服务端主动推送数据---", operationType, "socket-服务端主动推送数据", data);
                    }

                    if (operationType === 10) {
                        // 签到
                        // 暂时做跳转页面抽验
                        that.doUserSignDataList(content);
                        that.checkIsLearning();
                    }

                    if (operationType === 21) {
                        // 暂时做跳转页面抽验
                        that.doUserSignDataList(content);
                    }
                    if (operationType === 22) {
                        that.doUserSignDataList(content);
                    }
                    if (operationType === 23) {
                        // lzb 2020-06-24 socket - 后台需要提示
                        if (content.hasOwnProperty("msg")) {
                            if (content.msg && content.msg.length > 0) {
                                that.exitPlayerFullScreen();
                                that.stopTimerAndRemote();
                                that.pause();
                                that.$alert(content.msg, "提示", {
                                    confirmButtonText: "确定",
                                    showCancelButton: false,
                                    type: "warning",
                                }).then(() => {
                                    // 确定之后没有操作
                                    // 验证是否能开始上课, 不阻塞, 不能上课时直接弹窗
                                    that.checkIsLearning(() => {
                                        // 获取抽验数据20200412 新增获取抽验数据进入页面
                                        that.getStudentCheckDataPage((active) => {
                                            if (active) {
                                                if (that.isVideoPlayType) {
                                                    that.startLearn(true);
                                                } else {
                                                    that.startLearn();
                                                }
                                            }
                                        });
                                    });
                                });
                            }
                        }
                    }
                    if (operationType === 24) {
                        let second = data.content.studySpotCheckNotifyRealTime;

                        const message = `${second}秒后开始抽验`;
                        console.log(message);
                        that.showFullScreenMessage(message);
                        //  lzb 20230306 新增语音提示
                        if (that.isShowAudioSwitch && that.isOpenAudioTip) {
                            SpeechHelper.speakingText(message);
                        }
                    }
                    // 后台王俊lzb 20240321 新增操作类型
                    if (operationType === 25) {
                        that.stopClockTimer();
                        that.closeSubmitVideoProgessTimer();
                        that.stopTimerAndRemote();
                        // 视频暂停
                        that.videoParamter.pause = true;
                    }
                    if (operationType === 50 || operationType === 51 || operationType === 52) {
                        // 签到界面的操作类型, 课程0, 签到1, 抽验2, 签退3
                        if (
                            (that.activeTab === 1 && operationType === 50) ||
                            (that.activeTab === 2 && operationType === 51) ||
                            (that.activeTab === 3 && operationType === 52)
                        ) {
                            // 生成签到/抽验/签退二维码
                            let qrCodePushContentDto = content.qrCodePushContentDto;
                            that.generateQrCode(qrCodePushContentDto);
                        }
                    }
                    if (operationType === 65) {
                        let currentFinishedHours = content.currentFinishedHours;
                        let finishedTotalHours = content.finishedTotalHours;

                        if (currentFinishedHours !== undefined && currentFinishedHours != null) {
                            const key = that.getTimerKey();
                            storeDBManger.saveDataToLocalStorage(key, currentFinishedHours);
                        }
                        finishedTotalHours = parseInt(finishedTotalHours / 60);
                        that.offlineRecordData.finishedTotalHours = finishedTotalHours;
                        const isClock = content.isClock;
                        console.log("同步学时学时- 65, 当前学时:", currentFinishedHours, "是否计时-isClock =", isClock);
                        if (isClock) {
                            // 不是播放视频
                            if (!that.isVideoPlayType) return;
                            // 1 = 是计时终端
                            // 如果是播放中,还是可以计时
                            if (that.playerObject.playing) {
                                that.startClockTimer(isClock);
                            } else {
                                // 暂停播放
                                if (!that.isOfflineMeeting) {
                                    that.pause();
                                    that.checkParamSendSocketPauseTimer();
                                }
                            }
                        } else {
                            // 直接显示计时
                            that.stopClockTimer();
                            if (currentFinishedHours != undefined && currentFinishedHours != null) {
                                that.clockShow(currentFinishedHours);
                            }
                        }
                    }
                    // TODO 现场学习所有抽验完成通知
                    if (operationType === 70) {
                        console.log("现场学习所有抽验完成通知", content);
                    }
                    if (operationType === 100) {
                        // that.$router.push({path: "/home/list"});
                        console.log("单个人签退完成通知", content);
                        that.doUserSignDataList(content);
                        that.checkIsLearning();
                    }
                    // TODO 现场学习所有签退完成
                    if (operationType === 101) {
                        console.log("现场学习所有签退完成", content);
                        that.signOutToHomeList();
                    }
                    // lzb 2020-04-04 达到今日最大学习 - 服务器自动停止计时
                    if (operationType == 110) {
                        let message = "达到今日最大学习,将不能继续学习";
                        if (content.hasOwnProperty("msg")) {
                            message = content.msg;
                        }
                        // 暂停播放器
                        that.pause();
                        // 暂停计时
                        that.stopClockTimer();
                        that.closeSubmitVideoProgessTimer();
                        that.exitPlayerFullScreen();
                        that.$alert(message, "提示", {
                            confirmButtonText: "确定",
                            showCancelButton: false,
                            type: "warning",
                        }).then(() => {
                            // 确定之后没有操作
                        });
                    }
                    // 弹幕
                    if (operationType === 202) {
                        // 在mixins文件中的videoBaberrage.js
                        // fbz 2020-08-13 弹幕
                        console.log("收到弹幕的类型===", 202);
                        let pushTime = setTimeout(() => {
                            that.addBaberRageList(content);
                        }, 3000);
                        that.$once("hook:beforeDestroy", () => {
                            clearInterval(pushTime);
                        });
                    }
                    if (operationType === 501) {
                        // lzb 2020-06-24 暂时不处理
                        // that
                        //   .$alert("您已经在其它地方登陆学习2!", {
                        //     showClose: false,
                        //   })
                        //   .then(() => {
                        //     that.toHomeList();
                        //   });
                    }
                } else {
                    console.info("被动消息接收失败", data);
                    // HudGlobal.showErrorWithMessage("消息接收失败");
                }
            });
        },

        /**
         * 暂停本地计时与远程计时
         */
        stopTimerAndRemote() {
            // 停止视频播放
            this.pause();
            // 停止计时器
            this.stopClockTimer();
            this.sendSocketPauseTimer();
        },

        /*
         * 跳转首页
         * */
        toHomeList(params = {}, sendStop = false) {
            const that = this;
            if (sendStop) {
                that.sendSocketPauseTimerForLeave();
                that.sendSocketPauseTimer();
                that.activeTab !== 0 && that.closeOtherQrcode(0);
            }

            gotoHomeList(params);
        },

        /*
         * 签退完成提示并跳转首页
         * */
        signOutToHomeList() {
            const that = this;
            if (!this.signOutToHomeListFun) {
                this.signOutToHomeListFun = true;
                this.exitPlayerFullScreen();
                this.$confirm("签退完成, 是否立即退出", "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: false,
                    showCancelButton: false,
                    closeOnClickModal: false,
                })
                    .then(() => {
                        that.toHomeList({}, false);
                    })
                    .catch(function() {});
            }
        },

        /*
         * 跳转签到/抽验/签退页面 0或空为签到, 1抽验, 2签退
         * */
        toSign(signType) {
            TBDriver.resetSteps();

            // 上传一次进度
            this.sendCourseProgress();

            // 停止视频进度上传
            this.closeSubmitVideoProgessTimer();

            // 停止计时器
            this.stopClockTimer();
            // 关闭socket连接
            socketjs.close();

            let offlineRecordId = this.getOfflineRecordId();
            let signinParams = {};
            signinParams.offlineRecordId = offlineRecordId;

            // 保存classroomid lzb 20200409
            this.storeClassRoomId(offlineRecordId, this.offlineRecordData.classroomId);

            if (this.signLogList.validateNumber) {
                signinParams.validateNumber = this.offlineRecordData.validateNumber;
            }

            setOfflineRecordIdToDB(signinParams.offlineRecordId);

            // 签到界面的操作类型, 0或空为签到, 1抽验, 2签退
            this.storeofflineSignTye(signType);
            let name = TrackConstants.eventNameKeys.video_training_menu_pc_check;
            if (signType == 1) {
                name = TrackConstants.eventNameKeys.video_training_menu_pc_sampling;
            } else if (signType == 2) {
                name = TrackConstants.eventNameKeys.video_training_menu_pc_sign_out;
            }
            TibiMatomoManger.sendVideoTrainingTrackEvent(name);
            this.$router.push({
                path: COMMON.VERIFY_PHOTO,
                query: {
                    type: this.routerParam.type,
                    meetingSelectType: this.meetingObject.selectType,
                    meetingCourseId: this.playCourse && this.playCourse.courseId,
                    ...signinParams,
                },
            });
        },

        //=============================================现场会议 start================================================//

        /**
         * 会议内容
         */
        onClickMeetingContentSelect(type) {
            this.meetingObject.selectType = type;

            // 点击之后 默认选择
            switch (type) {
                case 0: {
                    // 会议简介
                    this.playerObject.playing && this.pause();
                    this.startLearn(false);
                    break;
                }
                case 1: {
                    // 会议视频
                    if (this.offlineRecordData.courseDtoList && this.offlineRecordData.courseDtoList.length > 0) {
                        // 默认第一个
                        const firstItem = this.offlineRecordData.courseDtoList[0];
                        this.onNodeClickEvent(firstItem);
                    } else {
                        HudGlobal.showWarningWithMessage("暂无会议视频");
                    }

                    break;
                }
                case 2: {
                    this.playerObject.playing && this.pause();
                    // 会议文档
                    if (this.offlineRecordData.docList && this.offlineRecordData.docList.length > 0) {
                        // 默认第一个
                        const firstItem = this.offlineRecordData.docList[0];
                        this.onNodeClickEvent(firstItem);
                        this.startLearn(false);
                    } else {
                        HudGlobal.showWarningWithMessage("暂无会议文档");
                    }

                    break;
                }
                case 3: {
                    this.playerObject.playing && this.pause();
                    // 图片
                    if (this.offlineRecordData.picList && this.offlineRecordData.picList.length > 0) {
                        // 默认第一个
                        const firstItem = this.offlineRecordData.picList[0];
                        this.onNodeClickEvent(firstItem);
                        this.startLearn(false);
                    } else {
                        HudGlobal.showWarningWithMessage("暂无会议图片");
                    }

                    break;
                }
            }
        },

        //=============================================现场会议 end================================================//

        /**
         * 存储开始的item.offlineRecordId到本地
         *
         */
        storeClassRoomId(offlineRecordId, classRoomId) {
            if (classRoomId) {
                StoreDBManger.removeContainerOfKey("classRoomId");
                const key = offlineRecordId + "classRoomId";
                window.localStorage.setItem(key, classRoomId);
            }
        },

        /*
         * 存储签到界面的操作类型, 0或空为签到, 1抽验, 2签退
         * */
        storeofflineSignTye(signType) {
            if (signType != null) {
                StoreDBManger.saveDataToLocalStorage(StoreDBManger.storageKeys.SELECT_SIGN_TYPE, signType);
            }
        },

        /*
         * 生成二维码
         * */
        generateQrCode(content) {
            let contentStr = JSON.stringify(content);
            // 应该只处理抽验数据
            this.$refs.qrCodeLogoBox && this.$refs.qrCodeLogoBox.toQrCode(contentStr);
        },

        /*
         * 通用数据发送, 目前传入操作类型即可,
         * */
        sendCommonSocketServe(operationType) {
            // lzb 2020-06-23 V1.0.9 后台需要
            let offlineRecordId = this.getOfflineRecordId();
            if (!offlineRecordId || offlineRecordId.length == 0) {
                console.log("未发送socket,因为参数未null-offlineRecordId =", offlineRecordId);
                return false;
            }
            this.qrcodeSignIn.params.offlineRecordId = offlineRecordId;
            this.qrcodeSignIn.params.planId = this.offlineRecordData.planId;
            this.qrcodeSignIn.params.classroomId = this.offlineRecordData.classroomId;
            this.qrcodeSignIn.params.operationType = operationType;
            let params = {};
            let content = {};
            Object.assign(content, this.qrcodeSignIn.params);

            params.content = content;
            params.userId = this.$store.getters.userId;
            // lzb 2020-06-23 V1.0.9 后台需要
            params.sourceType = 15;
            socketjs.send(this.socketOnlineEvent, params);
        },

        /*
         *  处理签到, 抽验, 签退数据
         * */
        doUserSignDataList(data) {
            console.log("处理签到, 抽验, 签退数据,", data, "当前标签:", this.activeTab, "操作类型:", data.operationType, data);
            let operationType = data.operationType;
            if (this.activeTab === 1 && operationType === 10 && data.validateUserList) {
                for (let i = 0; i < data.validateUserList.length; i++) {
                    let validateUser = data.validateUserList[i];
                    this.signLogList.list.unshift(validateUser);
                    // 人数总数增加
                    this.signLogList.total++;
                }

                //console.log("签到数据", this.signLogList.list);
            } else if (this.activeTab === 2 && data && data.validateUserList) {
                // 在标签抽验中, 且允许不去抽验界面, && this.ctrlParameter.offlineSkipPlayerPageAuth === 1

                if (data.operationType === 21) {
                    // 已经抽验的数据, 移除
                    for (let i = 0; i < data.validateUserList.length; i++) {
                        let validateUser = data.validateUserList[i];
                        this.signLogList.list = this.filterValidateUserArrayListByUser(this.signLogList.list, validateUser);
                        // this.signLogList.list = this.signLogList.list.filter(
                        // user => user.userId !== validateUser.userId);
                    }
                } else if (data.operationType === 22) {
                    // 需要抽验的数据
                    this.signLogList.validateNumber = data.validateNumber;
                    this.signLogList.list = [];
                    this.signLogList.list = data.validateUserList;

                    // 数据有 -lzb
                    if (data.validateUserList && data.validateUserList.length > 0) {
                        if (this.ctrlParameter.offlineSkipPlayerPageAuth === 0) {
                            //强制
                            // this.$confirm("开始抽验", "提示", {
                            //     confirmButtonText: '确定',
                            //     type: 'warning',
                            //     closeOnClickModal: false,
                            // }).then(() => {
                            //     this.toSign(1);
                            // }).then(() => {
                            //     this.startAll();
                            // });
                            this.startForceSignAlert();
                        } else {
                            // 非强制
                            this.showCheckTip();
                        }
                    }
                }

                // 如果没有抽验记录了, 关闭所有
                if (this.signLogList.list && this.signLogList.list.length === 0) {
                    this.$message.closeAll();
                }
                console.log("需要抽验的数据列表:", this.signLogList.list, "原始数据:", data.validateUserList);
            } else if (this.activeTab === 3 && operationType === 100 && data.validateUserList) {
                for (let j = 0; j < data.validateUserList.length; j++) {
                    let validateSignOutUser = data.validateUserList[j];
                    this.signLogList.list.unshift(validateSignOutUser);
                    // 人数总数增加
                    this.signLogList.total++;
                }
                // TODO;
                console.log("需要签退的数据列表:", this.signLogList.list, "原始数据:", data.validateUserList);
            } else if (data && data.operationType === 22) {
                console.log("需要抽验的数据列表[未在标签中], 原始数据: ", data.validateUserList);
                this.signLogList.validateNumber = data.validateNumber;
                let fullScreen = this.isFullScreen();
                if (fullScreen) {
                    this.exitFullScreen();
                }

                console.log("抽验跳转参数: ", this.ctrlParameter.offlineSkipPlayerPageAuth);
                if (this.ctrlParameter.offlineSkipPlayerPageAuth === 0 && data.validateUserList && data.validateUserList.length > 0) {
                    this.startForceSignAlert();
                } else {
                    console.log("开始抽验...............但不强制跳转...");
                    // 需要抽验的数据
                    this.signLogList.list = data.validateUserList;
                    this.showCheckTip();
                }
            }
        },
        /**
         * 过滤数组操作的数据
         */
        filterValidateUserArrayListByUser(sourceList, user) {
            if (!sourceList || sourceList.length == 0 || user == null) {
                return;
            }
            const resultList = [];
            Object.assign(resultList, sourceList);
            for (let i = 0; i < resultList.length; i++) {
                const item = resultList[i];
                // 如果身份和userid都相等
                if (item.userId == user.userId && item.identityCode == user.identityCode) {
                    // 人数总数
                    this.signLogList.total--;
                    resultList.splice(i, 1);
                    break;
                }
            }
            return resultList;
        },

        /**
         * 强制抽验alert
         */
        startForceSignAlert(msg) {
            const that = this;
            if (this.dialogCommonObject.isVisible && this.dialogCommonObject.tag === 1) {
                console.log("已经有弹框");
                return;
            }
            this.stopTimerAndRemote();
            this.exitPlayerFullScreen();
            const message = msg ? msg : "开始抽验！请选择本页扫码验证或跳转抽验页面人脸识别验证！";

            this.dialogCommonObject.isVisible = true;
            this.dialogCommonObject.content = message;
            this.dialogCommonObject.tag = 1;
            this.dialogCommonObject.isContianerCancel = true;
            this.dialogCommonObject.ensureButtonTitle = "跳转界面验证";
            this.dialogCommonObject.cancelButtonTitle = "扫码验证或继续学习";
            this.dialogCommonObject.supportMaskClick = false;

            // that.$confirm(message, "提示", {
            //     confirmButtonText: '跳转界面验证',
            //     cancelButtonText:"扫码验证",
            //     type: 'warning',
            //     closeOnClickModal: false,
            // }).then(() => {
            //     that.toSign(1);
            //
            // }).catch(() => {
            //
            // });
        },

        /**
         * 弹框回调
         */
        onDialogCommonCallBackEvent(item) {
            const tag = item.tag;
            // 强制抽验alert
            if (tag == 1) {
                let name = TrackConstants.eventNameKeys.video_training_menu_dialog_to_sign;
                if (item.type == 1) {
                    // 确定
                    // 跳转界面验证
                    this.toSign(1);
                } else {
                    // 扫码验证或继续学习
                    // 取消
                    name = TrackConstants.eventNameKeys.video_training_menu_dialog_continue_to_sign;
                    this.continuePlayStudy();
                }
                TibiMatomoManger.sendVideoTrainingTrackEvent(name);
            }
            // 网络连接检测
            if (tag === 999) {
                if (item.type == 1) {
                    //   // 确定重连
                    this.pingObject.pingDialogIsVisible = false;
                    this.onPingConnectEvent();
                } else {
                    // 退出学习
                    this.pingObject.pingDialogIsVisible = false;
                    this.toSign(2);
                }
            }
        },

        /*
         * 显示抽验提示
         * */
        showCheckTip() {
            this.$message.closeAll();

            // 切换到抽验标签
            this.activeTab = 2;
            this.signType.phoneSign = 0;

            if (this.$refs.qrCodeLogoBox) {
                this.$refs.qrCodeLogoBox.clearQrCode();
            }
            this.qrcodeSignIn.openSignQrcode.push(54);
            this.closeOtherQrcode(54);
            this.signInForPhone(51);

            this.activeTabName = "抽验";
            this.signType.signTypeName = "抽验";

            // this.showFullScreenMessage("开始抽验了！请在本页扫码或跳转抽验界面人脸识别验证需要抽验的学员！");
            this.$message({
                message: "开始抽验了！请在本页扫码或跳转抽验界面人脸识别验证需要抽验的学员！",
                duration: 120000,
                type: "warning",
                offset: 250,
                showClose: false,
            });
            this.fullScreenAddBrotherEle();
        },

        /*
         * 请求抽验数据
         * */
        getCheckList() {
            this.sendCommonSocketServe(22);
        },

        /*
         * 请求手机二维码签到
         * */
        signInForPhone(operationType) {
            this.sendQrcodeSocket(operationType);
        },

        /*
         * 发送签到/签退/抽验二维码
         * */
        sendQrcodeSocket(operationType) {
            operationType = operationType || this.qrcodeSignIn.params.operationType;
            // console.log("主动发送-----发送签到/签退/抽验二维码 --", operationType);
            this.sendCommonSocketServe(operationType);
        },
        /**
         * 切换操作标签
         * @param type 1:添加埋点
         * */
        changeTab(type, val) {
            if (val === undefined || val == null) {
                val = this.activeTab;
            } else {
                TBDriver.resetSteps();
            }
            let name = "";
            // console.log("切换操作标签, 课程0, 签到1, 抽验2, 签退3, 当前值:", val);
            // 左侧目录操作, 课程0, 签到1, 抽验2, 签退3
            // 关闭操作 53签到, 54抽验, 55签退, 关闭所有传0
            switch (val) {
                case 0: {
                    // 课程目录
                    name = TrackConstants.eventNameKeys.video_training_menu_tab_course;
                    this.activeTabName = this.isOfflineMeeting ? "会议" : "课程目录";
                    // 关闭正在进行的签到/抽验/签退
                    this.closeOrOpenQrcode();
                    this.closeOtherQrcode(0);
                    break;
                }
                case 1: {
                    // 签到
                    name = TrackConstants.eventNameKeys.video_training_menu_tab_check;
                    this.activeTabName = "签到";
                    if (this.ctrlParameter.offlinePlayerPageSignin === 1) {
                        this.signType.phoneSign = 1;
                    } else {
                        this.signType.phoneSign = 0;
                        if (this.$refs.qrCodeLogoBox) {
                            this.$refs.qrCodeLogoBox.clearQrCode();
                        }
                        this.qrcodeSignIn.openSignQrcode.push(53);
                        this.closeOtherQrcode(53);
                        this.signInForPhone(50);
                        this.signType.signTypeName = "签到";

                        this.getStudentsignInDataPage();
                    }
                    break;
                }
                case 2: {
                    //抽验2
                    name = TrackConstants.eventNameKeys.video_training_menu_tab_sampling;
                    this.activeTabName = "抽验";
                    if (this.ctrlParameter.offlinePlayerPageAuth === 1) {
                        this.signType.phoneSign = 1;
                    } else {
                        this.signType.phoneSign = 0;

                        if (this.$refs.qrCodeLogoBox) {
                            this.$refs.qrCodeLogoBox.clearQrCode();
                        }
                        this.qrcodeSignIn.openSignQrcode.push(54);
                        this.closeOtherQrcode(54);
                        this.signInForPhone(51);

                        this.signType.signTypeName = "抽验";

                        // 抽验数据目前只有从socket中请求
                        this.getCheckList();
                        this.getStudentCheckDataPage();
                    }
                    // 2020-08-27 抽验才会有炒作引导
                    this.checkLivelearningOperationGuide();
                    break;
                }
                case 3: {
                    // 签退
                    name = TrackConstants.eventNameKeys.video_training_menu_tab_sign_out;
                    this.activeTabName = "签退";
                    // 签退
                    if (this.ctrlParameter.offlinePlayerPageSignout === 1) {
                        this.signType.phoneSign = 1;
                    } else {
                        this.signType.phoneSign = 0;

                        if (this.$refs.qrCodeLogoBox) {
                            this.$refs.qrCodeLogoBox.clearQrCode();
                        }
                        this.qrcodeSignIn.openSignQrcode.push(55);
                        this.closeOtherQrcode(55);
                        this.signInForPhone(52);

                        this.signType.signTypeName = "签退";

                        this.getStudentsignOutDataPage();
                    }
                    break;
                }
                default: {
                    break;
                }
            }
            if (type == 1) {
                TibiMatomoManger.sendVideoTrainingTrackEvent(name);
            }
            this.activeTab = val;
        },

        /*
         * 关闭二维码签到
         * */
        closeQrcodeSign() {
            let activeTab = this.activeTab;
            let name = TrackConstants.eventNameKeys.video_training_menu_close_qrcode_sign;
            if (!this.signType.phoneSignOpenArr.includes(activeTab)) {
                // 开启二维码
                name = TrackConstants.eventNameKeys.video_training_menu_open_qrcode_sign;
            }
            TibiMatomoManger.sendVideoTrainingTrackEvent(name);
            this.closeOrOpenQrcode();
        },
        closeOrOpenQrcode() {
            // 开启或关闭二维码
            if (this.signType.phoneSignOpenArr.includes(this.activeTab)) {
                this.signType.phoneSignOpenArr = this.signType.phoneSignOpenArr.filter((val) => val !== this.activeTab);
            } else {
                this.signType.phoneSignOpenArr.push(this.activeTab);
            }
        },
        /*
         * 关闭其它二维码推送, 除了当前的操作, 全部关闭操作 53签到, 54抽验, 55签退, 关闭所有传0
         * */
        closeOtherQrcode(currSignOperate) {
            // 所有历史的推送
            let openSignQrcodeArr = this.qrcodeSignIn.openSignQrcode;
            for (let i = 0; i < openSignQrcodeArr.length; i++) {
                let qrcodeOperationType = openSignQrcodeArr[i];
                if (qrcodeOperationType === currSignOperate) {
                    continue;
                } else {
                    this.qrcodeSignIn.openSignQrcode.splice(this.qrcodeSignIn.openSignQrcode.indexOf(qrcodeOperationType), 1);
                    this.signInForPhone(qrcodeOperationType);
                }
            }
        },

        /*
         * 通用参数封装
         * */
        getParamForm: function(param) {
            let baseQuery = {};
            baseQuery.orgId = this.$store.getters.orgId;
            baseQuery.userId = this.$store.getters.userId;
            baseQuery.realName = this.$store.getters.realName;
            // lzb 20200408 课件id
            if (this.playCourse) {
                baseQuery.courseId = this.playCourse.courseId;
            }
            // baseQuery.trainingId = this.routerParam.trainingId;
            baseQuery.planId = this.routerParam.planId;
            // baseQuery.hoursRecordId = this.routerParam.hoursRecordId;
            let offlineRecordId = this.getOfflineRecordId();
            baseQuery.offlineRecordId = offlineRecordId;
            baseQuery.classroomId = this.routerParam.classroomId;
            baseQuery.sourceType = 15; // 10：pc网络，14：现场考试，15：pc现场学习，20：Android，21：iOS，22：公众号，23：小程序
            baseQuery.signSource = 10; // 10：pc,20：Android，21：iOS，22：公众号，23：小程序
            baseQuery.ip = "127.0.0.1";
            // baseQuery.dutyCode = this.dutyObject.dutyCode;
            param = Object.assign(param, baseQuery);
            return param;
        },
        /**
         *  获取offlineRecordId
         */
        getOfflineRecordId() {
            // 1. 先从网络请求返回取值
            let offlineRecordId = null;
            if (this.offlineRecordData && this.offlineRecordData.offlineRecordId && this.offlineRecordData.offlineRecordId.length !== 0) {
                offlineRecordId = this.offlineRecordData.offlineRecordId;
                return offlineRecordId;
            }

            //  2. 从路由取值
            offlineRecordId = this.routerParam.offlineRecordId;
            if (offlineRecordId && offlineRecordId.length !== 0) {
                return offlineRecordId;
            }

            // 从本地取值
            offlineRecordId = getOfflineRecordIdFromDB();
            return offlineRecordId;
        },

        /*
         * 通用参数封装2 - 外层
         * */
        getSocketParam: function(param) {
            let socketParam = {};
            socketParam.userId = this.$store.getters.userId;
            socketParam.trainingId = this.routerParam.trainingId;
            socketParam.planId = this.routerParam.planId;
            socketParam.content = param;
            return socketParam;
        },

        /*
         * 发送SOCKET数据
         * */
        sendSocket: function(param) {
            console.log("主动发送socket请求参数----", param.content.operationType, param);

            if (param.content.offlineRecordId) {
                param.sourceType = 15;
                socketjs.send(this.socketOnlineEvent, param);
            } else {
                console.log("socket请求参数缺少offlineRecordId----", param);
            }
        },

        /*
            * 提交学时视频进度
                 条件: 开始播放视频
                       抽验/签退跳转
                       刷新页面
                       视频结束
            * */
        startSubmitVideoProgessTimer: function() {
            let that = this;

            clearInterval(this.submitTimer);
            this.submitTimer = null;

            let commitDuration = this.ctrlParameter.studyCourseProgressCommitDuration;
            commitDuration = parseInt(commitDuration);
            let courseInterval = commitDuration * 1000;
            // console.log("视频提交间隔时间:", courseInterval);
            this.submitTimer = setInterval(sendCourseFun, courseInterval);

            function sendCourseFun() {
                // console.log("还有定时器", that.submitTimer);
                that.sendCourseProgress();
            }
        },

        /*
         * 停止提交进度 计时器 关闭视频进度提交
         * */
        closeSubmitVideoProgessTimer() {
            if (this.videoParamter.pause === false) {
                this.videoParamter.pause = true;
                this.pause();
            }

            let _this = this;
            clearInterval(_this.submitTimer);
            _this.submitTimer = null;
            // console.log("清除定时器submitTimer:", this.submitTimer);
        },

        /*
         * 上传视频进度
         * */
        sendCourseProgress(operationType) {
            // 向后台发送视频进度
            try {
                let courseParam = {};
                courseParam.operationType = operationType || 14;
                if (this.routerParam.currentCourseId) {
                    courseParam.courseId = this.routerParam.currentCourseId;
                } else {
                    courseParam.courseId = this.playCourse.courseId;
                }
                // fbz 2020-06-10 courseId不能为空
                if (!courseParam.courseId) {
                    return true;
                }

                courseParam.finishedCourseDuration = this.getCurrentTime();
                courseParam.courseDuration = this.getDuration();
                let offlineRecordId = this.getOfflineRecordId();
                courseParam.offlineRecordId = offlineRecordId;
                courseParam.classroomId = this.routerParam.classroomId;
                courseParam.planId = this.routerParam.planId;
                courseParam = this.getParamForm(courseParam);
                let courseProgressParam = this.getSocketParam(courseParam);
                this.sendSocket(courseProgressParam);
            } catch (e) {
                // 离开页面可能会失效, 直接忽略任何错误
                console.error(e);
            }
        },

        /*
         * 停止计时器
         * */
        stopClockTimer: function() {
            if (window.clockTimer) {
                window.clearInterval(window.clockTimer);
            }
            window.clockTimer = null;
        },

        /*
         * 开始计时 isClock =1 表示 是计时终端 0 不计时
         * */
        startClockTimer(isClock) {
            let that = this;

            this.stopClockTimer();
            if (isClock == null || isClock == 0) {
                // 不计时
                return;
            }

            window.clockTimer = setInterval(startTrainTimer, 1000);

            function startTrainTimer() {
                let key = that.getTimerKey();
                let time = Number(storeDBManger.getDataWithLocalStorage(key) || 0) + 1;
                storeDBManger.saveDataToLocalStorage(key, time);
                that.clockShow(time);
            }
        },

        /*
         * 计时器界面显示
         * */
        clockShow: function(time) {
            let key = this.getTimerKey();
            if (time == null || time == undefined) {
                // 需要通过socket获取时间
                time = 0;
                this.removeTimerKeyData();
                storeDBManger.saveDataToLocalStorage(key, time);
            }

            let h = parseInt(time / 3600);
            let m = parseInt((time / 60) % 60);
            let s = time % 60;
            this.clockTime = this.getFotmateTime(h) + ":" + this.getFotmateTime(m) + ":" + this.getFotmateTime(s);
        },
        /**
         *  获取计时的key
         */
        getTimerKey() {
            const key = this.getOfflineRecordId() + this.keys.STUDY_OFFLINE;
            return key;
        },

        /**
         * 移除计时数据
         */
        removeTimerKeyData() {
            const keyList = storeDBManger.getStorageAllKeys();
            if (!keyList || keyList.length == 0) return;
            // 移除数据
            for (let i = 0; i < keyList.length; i++) {
                const key = keyList[i];
                if (key.indexOf(this.keys.STUDY_OFFLINE) != -1) {
                    storeDBManger.removeDataWithLocalStorage(key);
                }
            }
        },

        /*
         * 格式化数字为双字符
         * */
        getFotmateTime: function(num) {
            return num >= 10 ? num : "0" + num;
        },

        /**
         * 继续学习
         */
        continuePlayStudy() {
            const that = this;
            if (this.isVideoPlayType) {
                if (this.playCourse && this.playCourse.courseId) {
                    this.startLearn();
                    this.onNodeClickEvent(this.playCourse);
                } else {
                    // 选择上次观看的文件
                    if (this.isOfflineMeeting) {
                        const meetingCourseId = this.$route.query && this.$route.query.meetingCourseId;
                        if (meetingCourseId && meetingCourseId.length > 0) {
                            let lastCourse = that.getLastCourseMeetingList(this.offlineRecordData, meetingCourseId);
                            if (lastCourse) {
                                // 文档直接计时
                                that.startLearn();
                                that.isVideoPlayType && that.onNodeClickEvent(lastCourse);
                            } else {
                                // 没有-直接计时
                                that.startLearn();
                            }
                        } else {
                            // 开始计时
                            that.startLearn();
                        }
                    } else {
                        // 查找上一个上次播放的视频, 延迟一点时间, 让树形数据加载完成
                        const lastCourse = that.getRequirePlayCourseWithCourseList(that.offlineRecordData.lastFileId);
                        if (lastCourse) {
                            that.onNodeClickEvent(lastCourse);
                        } else {
                            // 没有记录就播放第一个 - 不自动播放
                            const firstCourse = that.courseArrayList[0];
                            that.onNodeClickEvent(firstCourse);
                        }
                    }
                }
            } else {
                // 开始计时
                that.startLearn();
            }
        },

        /**
         * 点击课程列表
         */
        onNodeClickEvent(item) {
            // 如果是会议
            if (this.isOfflineMeeting) {
                const dataList = this.getMeetingSourceDataList(this.offlineRecordData);
                this.refreshMeetingContentStateList(item, dataList);
            }

            // 不是视频类型
            if (item.fileType !== 10) {
                this.playCourse = item;
                // 发送结束
                this.sendCourseProgress(15);
                return false;
            }

            // -1. 判断是否连接
            if (this.pingObject.disConnected) {
                console.log("当前网络未连接-不能播放视频");
                this.pause();
                return false;
            }

            // 0. 判断课件类型
            if (item.courseClazz != 21) {
                return false;
            }

            // 1.判断点击视频 - 是否是当前视频
            if (this.playCourse && item.courseId == this.playCourse.courseId) {
                this.refreshPlayingCourseList(this.playCourse.courseId);

                // 正在播放 lzb -20200409暂时不返回
                if (this.playerObject.playing) {
                    return false;
                }
            }

            // 2.判断点击视频完成之后被播放-不是会议 - 是否能重复播放
            if (item && item.isCourseFinished === 2 && !this.isOfflineMeeting && this.ctrlParameter.offlineVideoFinishedReplayEnable === 0) {
                HudGlobal.showWarningWithMessage("已完成的视频不能重复播放哦!");
                return false;
            }

            // 3. 是否必须顺序播放
            if (!this.isOfflineMeeting && this.ctrlParameter.videoPlayOrder == 1 && this.playCourse) {
                const nexCourse = this.getNextPlayCourseWithCourseList(this.playCourse.courseId);
                // 当前播放位置
                const currentPlayIndex = this.findCourseIndexFromCourseList(this.courseArrayList, this.playCourse.courseId);
                // 点击视频位置
                const currentSelectIndex = this.findCourseIndexFromCourseList(this.courseArrayList, item.courseId);
                // 如果点击index > 当前播放的index  说明是往后判断是否顺序练习
                if (currentPlayIndex < currentSelectIndex) {
                    // 当前视频没有播放完成
                    if (this.playCourse.isCourseFinished != 2) {
                        HudGlobal.showWarningWithMessage("只能按顺序点击视频播放!");
                        return false;
                    }

                    if (!nexCourse) {
                        HudGlobal.showWarningWithMessage("没有下一个视频!");
                        return false;
                    }

                    const nextIndex = this.findCourseIndexFromCourseList(this.courseArrayList, nexCourse.courseId);
                    if (nexCourse.courseId != item.courseId || currentSelectIndex != nextIndex) {
                        HudGlobal.showWarningWithMessage("只能按顺序点击视频播放!");
                        return false;
                    }
                } else {
                    // 点击index < 当前播放的index 说明判断是否有未完成
                    // 有未完成的
                    const hasUnFinishCourse = this.findCourseUnFinishCurrentCourseBefore(this.courseArrayList, item.courseId);
                    if (!hasUnFinishCourse) {
                        // 没有完成
                        HudGlobal.showWarningWithMessage("只能按顺序点击视频播放!");
                        return false;
                    } else {
                        // 找到未完成的第一个 是否和当前选择是同一个视频
                        if (hasUnFinishCourse.courseId != item.courseId) {
                            HudGlobal.showWarningWithMessage("只能按顺序点击视频播放!请选择第一个未完成视频");
                            return false;
                        }
                    }
                }
            }

            // 20221215 如果是鼠标移出进也不做其他操作
            if (this.dialogCenterJustDisappear == true) {
                this.dialogCenterJustDisappear = false;
                return false;
            }

            this.playerObject.playing = false;
            // 4.可以直接播放
            const that = this;
            let fileId = item.fileId;
            this.tcplayerReload({
                fileID: fileId,
            });

            // 将正在播放的课件记录
            that.playCourse = item;

            //5. 刷新UI
            // 视频可能未加载完时, 执行如下可能会出现
            setTimeout(() => {
                // 切换视频是暂停
                !that.isOfflineMeeting && that.pause();
                that.playCourse = item;
                console.log("onNodeClickEvent getCoursePropress rqm ");
                // 发送获取视频进度
                that.getCoursePropress(item);

                // 刷新正在播放的课件
                that.refreshPlayingCourseList(that.playCourse.courseId);
            }, 333);
        },

        /**
         * 找出下一个可以播放的视频
         */
        getNextPlayCourseWithCourseList(courseId) {
            const courseDtoList = this.courseArrayList;
            if (!courseDtoList || courseDtoList.length == 0) {
                HudGlobal.showWarningWithMessage("没有课程可以播放,请退出学习");
                return null;
            }
            if (!courseId) {
                console.log("查找下一个视频的id, courseId 不能为空");
                return null;
            }

            // 1. 当前课程的index
            const currentIndex = this.findCourseIndexFromCourseList(courseDtoList, courseId);

            if (currentIndex == -1) {
                console.log("未找到下一个视频");
                return null;
            }

            // 2.判断当前视频的index
            if (currentIndex >= courseDtoList.length - 1) {
                console.log("当前视频是最后一个视频");
                return null;
            }

            // 3.查找下一个视频
            let findCourse = null;
            for (let i = 0; i < courseDtoList.length; i++) {
                let courseDto = courseDtoList[i];
                // 课件的情况下
                if (courseDto && courseDto.courseClazz == 21) {
                    // 如果参数配置是播放完成可以在播放
                    if (this.ctrlParameter.offlineVideoFinishedReplayEnable == 1) {
                        // 不管 播不播放 直接找下一个
                        if (i == currentIndex + 1) {
                            findCourse = courseDto;
                            break;
                        }
                    } else {
                        // 如果配置是播放完成 不可以在播放 那么需要找的是未完成视频 下一个开始查找
                        if (courseDto.isCourseFinished !== 2 && i >= currentIndex + 1) {
                            findCourse = courseDto;
                            break;
                        }
                    }
                }
            }
            return findCourse;
        },

        /**
         * 找出课件在数组的index
         */
        findCourseIndexFromCourseList(courseDtoList, courseId) {
            if (!courseDtoList || courseDtoList.length == 0) {
                return -1;
            }
            if (!courseId) {
                return -1;
            }
            for (let i = 0; i < courseDtoList.length; i++) {
                let courseDto = courseDtoList[i];
                if (courseDto && courseDto.courseClazz == 21 && courseDto.courseId == courseId) {
                    return i;
                }
            }
            return -1;
        },
        /**
         * 找出当前课件之前时候有未完成的课件
         */
        findCourseUnFinishCurrentCourseBefore(courseDtoList, courseId) {
            if (!courseDtoList || courseDtoList.length == 0) {
                return null;
            }
            if (!courseId) {
                return null;
            }
            const currentIndex = this.findCourseIndexFromCourseList(courseDtoList, courseId);
            for (let i = 0; i < courseDtoList.length; i++) {
                let courseDto = courseDtoList[i];
                if (courseDto && courseDto.courseClazz == 21 && i <= currentIndex) {
                    if (courseDto.isCourseFinished != 2) {
                        return courseDto;
                    }
                }
            }
            return null;
        },

        /**
         * 刷新树形列表已经完成状态
         */
        refreshFinishCourseList(courseId) {
            const that = this;
            if (!that.offlineRecordData.courseDtoList) {
                return;
            }
            if (!courseId) {
                return;
            }

            // 标记课程已完成颜色
            markFinishCourse(that.offlineRecordData.courseDtoList);
            markFinishCourseArrayList(that.courseArrayList);

            // 标记目录颜色
            const listCount = that.offlineRecordData.courseDtoList.length;
            for (let i = 0; i < listCount; i++) {
                const treeDto = that.offlineRecordData.courseDtoList[i];
                markFinishClazzCourse(treeDto);
            }

            // 数组标记已完成
            function markFinishCourseArrayList(courseList) {
                if (!courseList || courseList.length == 0) return;
                for (let i = 0; i < courseList.length; i++) {
                    let courseDto = courseList[i];
                    if (courseDto && courseDto.courseClazz == 21 && courseDto.courseId == courseId) {
                        courseDto.isCourseFinished = 2;
                        break;
                    }
                }
            }

            //标记已完成颜色
            function markFinishCourse(courseList) {
                if (!courseList || courseList.length == 0) return;
                for (let i = 0; i < courseList.length; i++) {
                    let courseDto = courseList[i];
                    if (courseDto && courseDto.courseClazz == 21 && courseDto.courseId == courseId) {
                        courseDto.isCourseFinished = 2;
                        break;
                    } else {
                        if (courseDto && courseDto.children) {
                            markFinishCourse(courseDto.children);
                        }
                    }
                }
            }

            // 标记目录已完成
            function markFinishClazzCourse(treeDto) {
                if (!treeDto) return;
                if (treeDto && treeDto.courseClazz == 11) {
                    let finish = true;
                    if (treeDto.children) {
                        for (let j = 0; j < treeDto.children.length; j++) {
                            const courseDto = treeDto.children[j];
                            if (courseDto.courseClazz == 11 && courseDto.children) {
                                markFinishClazzCourse(courseDto);
                            } else {
                                if (courseDto.isCourseFinished != 2) {
                                    finish = false;
                                }
                            }
                        }
                    }
                    // 如果已经完成 -目录完成
                    if (finish) {
                        treeDto.isCourseFinished = 2;
                    }
                }
            }
        },

        /**
         * 刷新正在播放的树形课件
         */
        refreshPlayingCourseList(courseId) {
            const that = this;

            if (!that.offlineRecordData.courseDtoList) {
                return;
            }

            if (!courseId) {
                return;
            }

            // 标记正在播放颜色
            markPlayingCourse(that.offlineRecordData.courseDtoList);
            markPlayingCourseArrayList(that.courseArrayList);

            // 数组标记已完成
            function markPlayingCourseArrayList(courseList) {
                if (!courseList || courseList.length == 0) return;
                for (let i = 0; i < courseList.length; i++) {
                    let courseDto = courseList[i];
                    if (courseDto && courseDto.courseClazz == 21 && courseDto.courseId == courseId) {
                        courseDto.selected = true;
                    } else {
                        courseDto.selected = false;
                    }
                }
            }

            function markPlayingCourse(courseList) {
                if (!courseList || courseList.length == 0) return;
                for (let i = 0; i < courseList.length; i++) {
                    let courseDto = courseList[i];
                    if (courseDto && courseDto.courseClazz == 21 && courseDto.courseId == courseId) {
                        courseDto.selected = true;
                    } else {
                        if (courseDto && courseDto.children) {
                            markPlayingCourse(courseDto.children);
                        } else {
                            courseDto.selected = false;
                        }
                    }
                }
            }
        },

        /**
         * 是否自动播放下一个视频
         */
        autoPlayNextCourse() {
            const that = this;
            if (that.ctrlParameter.videoPcAutoplayNext === 1) {
                let nextCourse = null;
                if (this.isOfflineMeeting) {
                    //  会议
                    const currentIndx = this.offlineRecordData.courseDtoList.findIndex((item) => item.courseId === that.playCourse.courseId);
                    const nextIndex = currentIndx + 1;
                    if (nextIndex < this.offlineRecordData.courseDtoList.length) {
                        nextCourse = this.offlineRecordData.courseDtoList[nextIndex];
                    }
                    console.log("自动播放下一个视频==", nextCourse, currentIndx);
                } else {
                    // 培训
                    nextCourse = that.getNextPlayCourseWithCourseList(that.playCourse.courseId);
                }
                if (nextCourse) {
                    that.onNodeClickEvent(nextCourse);
                } else {
                    console.log("未找到下一个视频");
                }
            }
        },

        /*
         * 获取视频进度
         * */
        getCoursePropress(item) {
            // 获取视频进度
            let param = {};
            this.routerParam.currentCourseId = item.courseId;
            this.routerParam.currentFileId = item.fileId;
            this.routerParam.parentCourseId = item.parentId;
            param.courseId = item.courseId;
            // 获取视频进度
            param.operationType = 12;
            // 请求视频的进度
            param = this.getParamForm(param);

            let videoProgressParam = this.getSocketParam(param);
            this.sendSocket(videoProgressParam);
        },

        /*
         * 开始学习, 并通知后台开始计时 - 是否立即播放
         * */
        startLearn(play = true) {
            if (!this.isStartToLean) {
                // 视频准备播放 - 通知开始学习
                console.info("----------通知后台开始计时 - 20");
                let startLearnParam = {};
                startLearnParam.operationType = 20;
                startLearnParam = this.getParamForm(startLearnParam);
                let startLearnSocketParam = this.getSocketParam(startLearnParam);
                this.sendSocket(startLearnSocketParam);
            } else {
                // 恢复播放
                console.info("----------通知后台恢复计时 - 35");
                this.restartTimer(35);
                play && this.play();
            }
        },

        /*
         * 手动播放
         * */
        play() {
            if (this.isVideoPlayType && this.playLoadedMetaDataFinish) {
                this.$refs.player && this.$refs.player.play();
            }
            console.log("调用播放方法-----------------play---------------");
        },

        /*
         * 暂停视频
         * */
        pause() {
            // 保存视频进度
            this.currentVideoSecond = this.getCurrentTime();
            if (this.$refs.player && this.playLoadedMetaDataFinish) {
                this.$refs.player.pause();
            }
            console.log("调用暂停视频方法-----------------pause---------------");
        },

        /*
         * 销毁播放器
         * */
        playDestoryed() {
            if (this.$refs.player && this.playLoadedMetaDataFinish) {
                this.$refs.player.destroy();
            }
        },

        /*
         * 重载视频
         * */
        tcplayerReload(options) {
            this.videoObj.options.fileID = options.fileID;

            this.$nextTick(() => {
                if (this.$refs.player) {
                    console.log("重载视频--------------------------", options);
                    this.$refs.player.loadVideoById(options);
                }
            });
        },

        /*
         * 设置当前播放时间
         * */
        setCurrentTime(second) {
            if (!this.isVideoPlayType) return false;
            if (!second) {
                second = this.currentVideoSecond;
            }
            console.log("设置当前的播放时间-----", second, this.currentVideoSecond);

            !isNaN(second) && this.$refs.player && this.$refs.player.setCurrentTime(second);
        },

        /*
         * 获取当前已播放的时长
         * */
        getCurrentTime() {
            let currentTime = null;
            let _this = this;
            // console.log("getCurrentTime播放器", _this.$refs.player);
            if (this.playLoadedMetaDataFinish) {
                currentTime = _this.$refs.player && _this.$refs.player.getCurrentTime();
            }
            return parseInt(currentTime);
        },

        /*
         * 获取当前视频时长
         * */
        getDuration() {
            return parseInt(this.$refs.player.getDuration());
        },

        /*
         * 是否在全屏播放
         * */
        isFullScreen() {
            // fbz 2020-09-14 在自动退出视频播放的时候,存在无法找到player元素节点的情况
            return this.$refs.player && this.$refs.player.isFullScreen();
        },

        /*
         * 退出全屏播放
         * */
        exitFullScreen() {
            return this.$refs.player.exitFullScreen();
        },

        /*
         * 禁止拖动
         * */
        disableDrag(enable) {
            let progressControl = document.querySelector(".vjs-progress-control");
            let progressHolder = document.querySelector(".vjs-progress-holder");
            if (enable) {
                progressControl.setAttribute("title", "");
                progressControl.setAttribute("disabled", false);
                progressControl.style["pointer-events"] = "";
            } else {
                progressControl.style["pointer-events"] = "none";
                progressControl.setAttribute("disabled", true);
                progressControl.setAttribute("title", "不允许拖动");
            }
        },

        /*
         * 恢复计时, 默认暂停视频恢复
         * */
        restartTimer(operationType) {
            if (this.dialogCommonObject.isVisible && this.dialogCommonObject.tag === 1) {
                // rqm 20240517 修复一下bug
                //bug17411 【正式环境】现场学习，在有抽验弹框的情况下，视频与计时器均在正常播放与计时；
                // 原因：切换标签回来， window.addEventListener(visibilityChange, this.resetTrain);会进入resetTrain，导致最终进入monitorMouseEnter resumePlay
                // 该方法进入多次，导致changePageLock 变未true,然后进入initPage, 最终发送12获取进读，恢复了播放
                console.log("有抽验弹框，不能计时  rqm -----");
            } else {
                let param = {};
                param.operationType = operationType || 35; // 恢復课件計時
                // 请求视频的进度
                param = this.getParamForm(param);
                let playCourseParam = this.getSocketParam(param);
                this.sendSocket(playCourseParam);
            }
        },

        /**
         *  检测参数 - 是否通知后台不计时 2022-03-04
         */
        checkParamSendSocketPauseTimer() {
            if (this.ctrlParameter.videoStopTimerWithVideoPause == 1) {
                this.sendSocketPauseTimer();
            }
        },

        /*
         * 暂停后台计时
         * */
        sendSocketPauseTimer(operationType = 25) {
            // 暂停视频
            this.currentVideoSecond = this.getCurrentTime();
            console.log("暂停视频播放, 当前暂停状态:", this.videoParamter.pause);
            this.videoParamter.pause = true;

            // 调用后台暂停计时
            let param = {};
            param.operationType = operationType;
            // 请求暂停视频学时
            param = this.getParamForm(param);
            let pauseCourseParam = this.getSocketParam(param);
            this.sendSocket(pauseCourseParam);

            // 停止视频进度上传
            this.closeSubmitVideoProgessTimer();
        },

        /**
         *  离开页面 暂停后台计时 lzb 2022-03-02
         * */
        sendSocketPauseTimerForLeave() {
            // 保存视频进度进度
            this.currentVideoSecond = this.getCurrentTime();
            // console.log("暂停视频播放, 当前暂停状态:", this.videoParamter.pause);
            this.videoParamter.pause = true;

            // 调用后台暂停计时
            let param = {};
            param.operationType = 27;
            // 请求暂停视频学时
            param = this.getParamForm(param);
            let pauseCourseParam = this.getSocketParam(param);
            this.sendSocket(pauseCourseParam);

            // 停止视频进度上传  20221209 注释的
            this.closeSubmitVideoProgessTimer();
        },

        /*
         * 设置播放器播放拖放状态
         * */
        setPlayerProcessDragBar() {
            // console.log("设置播放器播放拖放状态", this.ctrlParameter.videoProcessBarDragEnable);
            if (this.ctrlParameter.videoProcessBarDragEnable === 0) {
                this.$refs.player.disableDrag();
            } else {
                this.$refs.player.enableDrag();
            }
        },

        /*
         * 视频控制
         * */
        watchPlayer(player) {
            let _this = this;
            player.on("ready", (msg) => {
                // 设置封面
                _this.$refs.player.setPosterImg(playerBg);
                // 延迟一点设置, 因为播放器本身也会操作
                setTimeout(function() {
                    _this.setPlayerProcessDragBar();
                }, 500);
            });
            player.on("loadstart", (msg) => {
                // 暂时不做任何事
            });
            // 全屏状态切换时触发  fbz 2020-08-26
            player.on("fullscreenchange", (value) => {
                _this.isFullScreenType = !_this.isFullScreenType;
            });
            // 开始播放或恢复播放
            player.on("play", (msg) => {
                // let fullScreen = _this.isFullScreen();
                // if (fullScreen) {
                //     _this.exitFullScreen();
                // }
                // lzb 2020-06-22  网络连接失败
                if (_this.pingObject.disConnected) {
                    HudGlobal.showWarningWithMessage("当前网络已断开,不能播放视频");
                    _this.pause();
                    _this.videoParamter.pause = true;
                    return false;
                }
                this.isPlay = true;
                // console.log("视频开始播放--------", _this.videoParamter.pause);
                _this.setPlayerProcessDragBar();
                // 如果会会议视频栏 或者不是现场会议
                if (_this.meetingObject.selectType == 1 || !_this.isOfflineMeeting) {
                    // 如果是暂停状态
                    if (_this.videoParamter.pause === true) {
                        // 播放器状态
                        _this.playerObject.playing = true;
                        //  恢复播放
                        _this.restartTimer(35);

                        // 开始提交学习进度
                        _this.startSubmitVideoProgessTimer();
                    } else {
                        _this.playerObject.playing = true;
                        // 开始提交学习进度
                        _this.startSubmitVideoProgessTimer();
                    }
                }
            });
            // lzb 新增
            //因缓冲而暂停或停止后恢复播放时触发，paused 属性为 false 。
            // 通常用这个事件来标记视频真正播放，play 事件只是开始播放，画面并没有开始渲染。
            player.on("playing", function() {
                // 播放器状态
                _this.playerObject.playing = true;
            });

            player.on("canplay", (msg) => {
                // console.log("可以播放了" || msg);
            });

            player.on("pause", (msg) => {
                // 播放器状态
                _this.playerObject.playing = false;
                // 需要上传课件进度
                _this.sendCourseProgress(14);

                if (_this.isVideoPlayType) {
                    // 发送 25 暂停计时器
                    _this.checkParamSendSocketPauseTimer();
                }
            });

            player.on("ended", (msg) => {
                console.log("====视频播放完成====", this.playCourse);
                // 调用后台暂停计时
                _this.currentVideoSecond = 0;

                // 暂停
                _this.videoParamter.pause = true;

                // 需要上传课件进度
                _this.sendCourseProgress(14);

                // 结束课件
                _this.sendCourseProgress(15);
            });

            // 视频播放错误
            player.on("error", (msg) => {
                console.log("视频出错了" || msg);
            });

            player.on("loadedmetadata", (res) => {
                // console.log("视频加载信息-----", res);
                _this.playLoadedMetaDataFinish = true;
            });

            // 视频拖动
            player.on("timeupdate", (msg) => {
                // v1.0.23 20221208 新增入参数据
                if (player.currentTime() == 0) {
                    if (_this.currentVideoSecond > 0) {
                        _this.setCurrentTime();
                    }
                }
            });
            player.on("seeked", () => {
                // rqmei 20221011 修复bug:15158 进度条拖动结束后，重新设置视频的当前进度
                console.log("seeked指定播放位置结束时触发*****************************", player.currentTime());
                _this.sendCourseProgress(14);
                _this.currentVideoSecond = _this.getCurrentTime();
            });
        },

        //============================================加载数据======================================//

        /**
         * 加载左侧tab标签header
         */
        loadLeftHeaderTabData() {
            const headTabs = [];
            // 1. 初始化
            headTabs.push({
                tabId: 0,
                tabName: this.isOfflineMeeting ? "会议" : "课程",
            });
            // 需要人像 且 支持app 操作 20210-09-01
            if (this.ctrlParameter.faceAuthEnable == 1 && this.ctrlParameter.faceLiveDetectionApp == 1) {
                // 2.判断是否有签到
                if (this.ctrlParameter.offlinePlayerPageSignin == 0) {
                    headTabs.push({
                        tabId: 1,
                        tabName: "手机签到",
                    });
                }
                // 3.判断是否有抽验
                if (this.ctrlParameter.offlinePlayerPageAuth == 0) {
                    headTabs.push({
                        tabId: 2,
                        tabName: "手机抽验",
                    });
                }

                // 4.判断是否有签退
                if (this.ctrlParameter.offlinePlayerPageSignout == 0) {
                    headTabs.push({
                        tabId: 3,
                        tabName: "手机签退",
                    });
                }
            }

            this.tabList = headTabs;

            this.meetingObject.selectType = this.$route.query.selectType;
            console.log("当前选择的类型=======", this.meetingObject.selectType);
        },

        /*
         * 验证是否正在上课, 是否可以进入学习中
         * */
        checkIsLearning(cb) {
            let params = {};
            params.offlineRecordId = this.getOfflineRecordId();
            params.classroomId = this.offlineRecordData.classroomId;
            params.planId = this.offlineRecordData.planId;
            params.userId = this.$store.getters.userId;

            const _this = this;
            hoursApi.checkOfflineToStart(params).then((res) => {
                if (res.success) {
                    _this.signLogList.signInNumber = res.data.signInCount;

                    // 这里也需要查询一次, 需要展示所有签到数量
                    _this.changeTab(0, this.activeTab);
                    cb && cb();
                } else if (res.code * 1 === 80231) {
                    // fbz 2021-08-13 学员进入
                    _this.signLogList.signInNumber = 0;
                    cb && cb();
                } else if (res.code * 1 == 80120) {
                    // fbz 2021-08-27 管理员未签到（允许学员未签到）
                    _this.exitPlayerFullScreen();
                    _this.stopTimerAndRemote();
                    // 关闭所有二维码推送 lzb 20240321
                    _this.closeOtherQrcode(0);
                    // 如果是会议且不是视频的情况下， 调用29暂停
                    if (_this.isOfflineMeeting && _this.meetingObject.selectType !== 1) {
                        _this.sendSocketPauseTimer(29);
                    }

                    _this
                        .$alert("请重新回到列表进入! 原因: " + res.description || "您没有签到或学习资格, 将会回到计划列表", "提示", {
                            confirmButtonText: "确定",
                            type: "warning",
                        })
                        .then(() => {
                            let toHomeParam = {};
                            toHomeParam.userId = this.$store.getters.userId;
                            _this.toHomeList(toHomeParam, true);
                        });
                    _this.signLogList.signInNumber = 0;
                } else {
                    cb && cb();
                }
            });
        },

        /**
         * 获取单个培训历史记录ID, 上个页面需要传入历史记录ID
         */
        getOfflineRecordDataOne() {
            console.log("getOfflineRecordDataOne start rqm");
            let offlineRecordId = this.getOfflineRecordId();
            let params = {
                offlineRecordId: offlineRecordId,
            };

            let _this = this;
            planApi.getOfflineRecordOne(params).then((res) => {
                if (!res.hasOwnProperty("success")) {
                    res = res.data;
                }
                if (res.success) {
                    let data = res.data;
                    // 处理培训管理人员
                    let offlinePlanLeaderDtoList = data.offlinePlanLeaderDtoList;
                    if (offlinePlanLeaderDtoList) {
                        for (let i = 0; i < offlinePlanLeaderDtoList.length; i++) {
                            let offlinePlanLeaderDto = offlinePlanLeaderDtoList[i];
                            if (offlinePlanLeaderDto.identityCode === Constants.organizeIndentityType.INDENTITY_SAFE_ADMIN) {
                                data.safeAdminName = offlinePlanLeaderDto.identityName;
                                data.safeAdmin = offlinePlanLeaderDto;
                            }
                            if (offlinePlanLeaderDto.identityCode === Constants.organizeIndentityType.INDENTITY_LECTURER) {
                                data.lecturerName = offlinePlanLeaderDto.identityName;
                                data.lecturer = offlinePlanLeaderDto;
                            }
                            if (offlinePlanLeaderDto.identityCode === Constants.organizeIndentityType.INDENTITY_ASSESSORS) {
                                data.auditorName = offlinePlanLeaderDto.identityName;
                                data.auditor = offlinePlanLeaderDto;
                            }
                        }
                    }

                    // 计算多少人未培训 planData.planDto.userCount - planData.planDto.finishCount
                    let planDto = data.planDto;
                    if (planDto) {
                        let userCount = planDto.userCount;
                        let studyFinishCount = planDto.studyFinishCount;
                        planDto.restCount = userCount - studyFinishCount;
                    }
                    // 参数获取完后处理逻辑  需要在回调之后处理数据 yxl 20230316
                    StoreTrainDBManger.getCurrentPlanItemFromDB(function(planItem) {
                        console.log("getOfflineRecordDataOne startgetCurrentPlanItemFromDB  handleCourseResponseData rqm");
                        if (!planItem) {
                            OfflineParam.init(planDto.planId, function() {
                                _this.setupInitPlanParam(OfflineParam.params);
                                _this.handleCourseResponseData(data);
                            });
                        } else {
                            _this.handleCourseResponseData(data);
                        }
                    });
                } else {
                    if (res.code !== 102) {
                        HudGlobal.showMessage(res.description || "获取培训记录信息失败!");
                    } else {
                    }
                }
            });
        },

        /**
         * 计算课件信息
         */
        handleCourseResponseData(data) {
            const that = this;

            // lzb 2020-04-23新增保存
            setOfflineRecordIdToDB(data.offlineRecordId);

            this.routerParam.offlineRecordId = data.offlineRecordId;
            this.routerParam.planId = data.planId;
            this.routerParam.classroomId = data.classroomId;

            this.offlineRecordData = data;

            // 学员签到数据查询参数构建初始化
            this.studentListParams.planId = data.planId;
            this.studentListParams.classroomId = data.classroomId;
            this.studentListParams.offlineRecordId = this.getOfflineRecordId();
            this.studentListParams.planName = data.planName;

            this.courseArrayList = this.handleTreeListToArrayList(this.offlineRecordData.courseDtoList);

            that.checkIsLearning(() => {
                // fbz 2021-07-27 (已签到人数没有及时获取正确的值)
                // that.getStudentsignInDataPage();
                // 获取抽验数据20200412 新增获取抽验数据进入页面
                that.getStudentCheckDataPage((activate) => {
                    if (that.isOfflineMeeting) {
                        that.initMeetingContentStateList(that.offlineRecordData);

                        // 直接使用视频赋值给课件
                        that.offlineRecordData.courseDtoList = that.offlineRecordData.videoList;
                        // 选择上次观看的文件
                        const meetingCourseId = that.$route.query && that.$route.query.meetingCourseId;
                        if (meetingCourseId && meetingCourseId.length > 0) {
                            const lastCourse = that.getLastCourseMeetingList(that.offlineRecordData, meetingCourseId);
                            if (lastCourse) {
                                if (!that.isVideoPlayType) {
                                    // 文档直接计时
                                    activate && that.startLearn();
                                }
                                activate && that.onNodeClickEvent(lastCourse);
                            } else {
                                // 没有-直接计时
                                activate && that.startLearn();
                            }
                        } else {
                            // 开始计时
                            activate && that.startLearn();
                        }
                    } else {
                        // 查找上一个上次播放的视频, 延迟一点时间, 让树形数据加载完成
                        const lastCourse = that.getRequirePlayCourseWithCourseList(that.offlineRecordData.lastFileId);
                        if (lastCourse) {
                               console.log("handleCourseResponseData onNodeClickEvent lastCourse getCoursePropress rqm ");
                            activate && that.onNodeClickEvent(lastCourse);
                        } else {
                             console.log("handleCourseResponseData onNodeClickEvent firstCourse getCoursePropress rqm ");
                            // 没有记录就播放第一个 - 不自动播放
                            const firstCourse = that.courseArrayList[0];
                            activate && that.onNodeClickEvent(firstCourse);
                        }
                    }
                });
            });
        },

        /**
         *  获取课件数组数据
         */
        handleTreeListToArrayList(treeList) {
            if (!treeList || treeList.length == 0) {
                return [];
            }
            const dataList = [];
            // 执行递归
            treeToDataList(dataList, treeList);

            return dataList;

            // 递归数据转化为数组
            function treeToDataList(dataList, treeList) {
                if (!treeList || treeList.length == 0) {
                    return;
                }
                for (let i = 0; i < treeList.length; i++) {
                    const item = treeList[i];
                    // 设置增加属性
                    Vue.set(item, "selected", false);
                    // 如果是课件
                    if (item.courseClazz == 21) {
                        dataList.push(item);
                    } else {
                        if (item.children) {
                            treeToDataList(dataList, item.children);
                        }
                    }
                }
            }
        },

        /**
         * 找出上一次需要播放的课程 courseId 指定课程
         */
        getRequirePlayCourseWithCourseList(fileId) {
            // 没有课程
            let course = null;
            const courseDtoList = this.courseArrayList;
            if (!courseDtoList || courseDtoList.length == 0) {
                return null;
            }

            // 如果有指定课程
            if (fileId) {
                course = this.findAppointCourseFromCourseList(courseDtoList, fileId);
                // 如果找到了
                if (course) {
                    return course;
                }
            }
            // 没有指定就找出第一个. 默认第一个
            course = courseDtoList[0];
            return course;
        },

        /***
         * 找出数组中指定的课件 通过fileId
         */
        findAppointCourseFromCourseList(courseDtoList, fileId) {
            if (!courseDtoList || courseDtoList.length == 0) {
                return null;
            }
            if (!fileId) {
                return null;
            }
            for (let i = 0; i < courseDtoList.length; i++) {
                let courseDto = courseDtoList[i];
                if (courseDto && courseDto.courseClazz == 21 && courseDto.fileId == fileId) {
                    return courseDto;
                }
            }
            return null;
        },

        /*
         * 查询学员签到记录 签到类型（0:待签到，10：已签到，20：抽验，30：签退）
         * */
        getStudentsignInDataPage() {
            this.studentListParams.signType = 10;
            this.getStudentDataPage();
        },

        /*
         * 查询学员抽验记录 签到类型（0:待签到，10：已签到，20：抽验，30：签退）
         * */
        getStudentCheckDataPage(cb) {
            this.studentListParams.signType = 20;

            const that = this;
            this.getStudentDataPage((dataList) => {
                // 20240321 -刘子彬 进入页面调动抽验 有数据提示
                if (that.activeTab == 0 && dataList && dataList.length > 0) {
                    const message = "还有学员未抽验！请选择本页扫码验证后继续学习 或 跳转抽验页面人脸识别验证！ \n" + "注意：未抽验的学员系统会自动签退！";
                    that.startForceSignAlert(message);
                    cb && cb(false);
                } else {
                    cb && cb(true);
                }
            });
        },

        /*
         * 查询学员签到记录 签到类型（0:待签到，10：已签到，20：抽验，30：签退）
         * */
        getStudentsignOutDataPage() {
            this.studentListParams.signType = 30;
            this.getStudentDataPage();
        },
        /**
         * 监听current-page
         */
        monitorCurrentPageEvent(val) {
            this.studentListParams.currentPage = val;
            this.getStudentDataPage();
        },

        /*
         * 获取培训学员列表, 分页
         * */
        getStudentDataPage(cb) {
            let params = {};
            Object.assign(params, this.studentListParams);
            params.offlineRecordId = this.getOfflineRecordId();
            params.identityCode = null;

            if (params.signType === 20) {
                params.state = 0;
            }

            let _this = this;

            hoursApi.getOfflineUserList(params).then((res) => {
                //  console.log("获取培训学员待签到列表", res);

                if (res.success && res.data) {
                    let data = res.data;

                    // 获取所有数据
                    _this.signLogList.total = res.total;
                    // 签到时才有签到总数
                    if (params.signType === 10) {
                        // fbz 20210727 修复签到人数只算学员
                        if (res.data) {
                            _this.signLogList.signInNumber = res.data.filter(
                                (item) => item.identityCode === Constants.organizeIndentityType.INDENTITY_STUDENT
                            ).length;
                        }
                    }

                    _this.signLogList.list = [];
                    _this.signLogList.list = _this.signLogList.list.concat(data);
                } else {
                    if (res.code !== 102) {
                        // HudGlobal.showMessage(res.description || "获取培训学员数据失败!");
                    } else {
                        _this.signLogList.list = [];
                    }
                } // 回调出去
                cb && cb(_this.signLogList.list);
            });
        },

        /*
         * 退出现场, 准备签退
         * */
        logoutDo() {
            HudGlobal.showAlertConfirmMessage("是否退出学习, 开始签退").then(() => {
                this.toSign(2);
            });
        },

        /*
         * 获取事件
         * */
        getVisibilityChangeEvent() {
            // TODO IE兼容性有问题

            let hidden, state, visibilityChange;
            if (typeof document.hidden !== "undefined") {
                hidden = "hidden";
                visibilityChange = "visibilitychange";
                state = "visibilityState";
            } else if (typeof document.mozHidden !== "undefined") {
                hidden = "mozHidden";
                visibilityChange = "mozvisibilitychange";
                state = "mozVisibilityState";
            } else if (typeof document.msHidden !== "undefined") {
                hidden = "msHidden";
                visibilityChange = "msvisibilitychange";
                state = "msVisibilityState";
            } else if (typeof document.webkitHidden !== "undefined") {
                hidden = "webkitHidden";
                visibilityChange = "webkitvisibilitychange";
                state = "webkitVisibilityState";
            }
            return { visibilityChange, hidden, state };
        },
        /*
         * 移除弹框
         * */
        removeVisibilityChange() {
            console.log("removeVisibilityChange resetTrain rqm");
            let { visibilityChange, hidden, state } = this.getVisibilityChangeEvent();
            window.removeEventListener(visibilityChange, this.resetTrain);
        },

        /*
         * 初始化页面隐藏事件
         * */
        initVisibilityChange() {
            console.log("initVisibilityChange resetTrain rqm");
            let { visibilityChange, hidden, state } = this.getVisibilityChangeEvent();
            window.addEventListener(visibilityChange, this.resetTrain);
        },
        /**
         * 移除监听
         */
        removeWindowListener() {
            window.removeEventListener("offline", this.pingObject.offline);
            window.removeEventListener("online", this.pingObject.online);
            // 停止心跳
            SocketHeart.stopPing();
            // 关闭心跳超时
            SocketHeart.stopPingTimeOutTimer();
        },

        /**
         *  监听鼠标进入
         */
        monitorMouseEnter() {
            // 用户正在浏览当前页面
            // console.log("=================恢复页面----或者监听鼠标进入");
            if (this.changePageLock || this.dialogCenterVisible) {
                return;
            }
            if (!this.isOfflineMeeting && this.ctrlParameter.videoStopTimerWithLeavePage == 1) {
                // 用户正在浏览当前页面
                if (this.changePageLock) {
                    return;
                }
                 console.log("resetTrain monitorMouseEnter resumePlay rqm ");
                this.resumePlay();
            }
        },
        /**
         * 监听鼠标离开
         */
        monitorMouseLeave() {
            // console.log("=================停止页面---焦点离开", this.playerObject.playing);
            if (!this.isOfflineMeeting && this.ctrlParameter.videoStopTimerWithLeavePage == 1) {
                this.changePageLock = false;
                // 用户没有浏览当前页面
                // 停止视频播放
                this.pause();
                // 停止计时器
                this.stopClockTimer();
                this.closeSubmitVideoProgessTimer();
                // 视频进度上传
                this.sendCourseProgress(14);
                // 离开页面暂停计时
                this.sendSocketPauseTimerForLeave();

                // lzb-20200217 解决socket重连问题
                // 关闭socket连接
                // socketjs.close();

                // 弹窗  yxl  v1.0.23  2022-11-28
                if (this.playerObject.playing) {
                    // 正在播放
                    this.dialogCenterVisible = true;
                }
            }
        },
        // 恢复播放
        resumePlay() {
            // yxl 2022-11-29 替换下面的方法
            const that = this;
            this.changePageLock = true;
            setTimeout(function() {
                // 如果还是恢复页面的状态才播放
                 console.log("resetTrain monitorMouseEnter resumePlay rqm that.changePageLock", that.changePageLock);
                 if (that.changePageLock) {
                     console.log("resumePlay rqm initPage");
                     that.initPage();
                     // 恢复播放 - 没有弹框播放
                     console.log("resetTrain monitorMouseEnter resumePlay rqm dialogCommonObject.isVisible", that.dialogCommonObject.isVisible);
                     if (!that.dialogCommonObject.isVisible) {
                         console.log("resetTrain monitorMouseEnter resumePlay rqm 发送35恢复播放");
                         that.restartTimer(35);
                         that.play();
                     }
                 }
            }, 999);

            // this.changePageLock = true;
            // this.initPage();
            // //todo 重新返回页面时拿离开时候的时间进行初始化
            // let key = this.getTimerKey();
            // let time = Number(storeDBManger.getDataWithLocalStorage(key));
            // // console.log("mounted",storeDBManger.getDataWithLocalStorage(key));
            // this.clockShow(time);
        },

        /*
         * 切换回来时加载页面数据
         * */
        resetTrain() {
              console.log("resetTrain  rqm ");
            let { visibilityChange, hidden, state } = this.getVisibilityChangeEvent();
            let _this = this;
            if (document[hidden]) {
                _this.monitorMouseLeave();
            } else {
                _this.monitorMouseEnter();
            }
        },
        /**
         *
         */
        changeWrapper() {
            const element = document.getElementsByClassName("el-message-box__wrapper");
            if (element && element.length !== 0) {
                element[0].style.display === "none" ? localStorage.setItem("wrapper", false) : localStorage.setItem("wrapper", true);
            } else {
                localStorage.setItem("wrapper", false);
            }

            // lzb 2020 -06 -22 新增通知
            const elementNotification = document.getElementsByClassName(" el-notification");
            if (elementNotification && elementNotification.length !== 0) {
                localStorage.setItem("wrapper", true);
            } else {
                localStorage.setItem("wrapper", false);
            }
        },
        /**
         * lzb - 新增在全屏幕显示messag
         */
        showFullScreenMessage(message, warning = false) {
            if (warning) {
                HudGlobal.showWarningWithMessage(message);
            } else {
                HudGlobal.showInfoWithMessage(message);
            }
            this.fullScreenAddBrotherEle();
        },
        /**
         * lzb - 全屏增加兄弟组件
         */
        fullScreenAddBrotherEle() {
            const fullScreen = this.isFullScreen();
            if (fullScreen) {
                const messageNodes = document.getElementsByClassName("el-message");
                const parentNode = document.getElementById("player-container-id");
                if (messageNodes && messageNodes.length > 0 && parentNode) {
                    for (let i = 0; i < messageNodes.length; i++) {
                        const messageNode = messageNodes[i];
                        parentNode.appendChild(messageNode);
                    }
                }
            }
        },

        /**
         * lzb - 退出全屏
         */
        exitPlayerFullScreen() {
            let fullScreen = this.isFullScreen();
            if (fullScreen) {
                this.exitFullScreen();
            }
        },

        /**
         * 检测操作引导
         */
        checkLivelearningOperationGuide() {
            if (this.isFristEntry) {
                this.isFristEntry = false;
                // 签到
                const that = this;
                GuideStepHelper.loadOperationGuideData(ConstantsGuideCode.videoPlayerGuideCodes, function(dataList) {
                    if (dataList == null || dataList.length == 0) {
                        return;
                    }
                    for (let i = 0; i < dataList.length; i++) {
                        const guide = dataList[i];
                        //是否已完成引导(0:未完成,1:已完成)
                        // guide.guideDone == 0
                        if (guide.guideDone == 0) {
                            const keyCode = guide.guideCode;
                            if (keyCode == "step221") {
                                TBDriver.addDriverStep(
                                    HomeGuideSteps.createLivelearningGuideSteps(
                                        "#step221",
                                        "抽验",
                                        "点击【手机抽验】，查看待抽验列表或关注弹幕中【待验】人员，学员手机登录扫码抽验",
                                        HudGlobal.getVue().$driverConstants.positionTypeRightCenter,
                                        221,
                                        221,
                                        false,
                                        true,
                                        false,
                                        ConstantsGuideCode.videoPlayerGuideCodes
                                    )
                                );
                            }
                            if (keyCode == "step222") {
                                TBDriver.addDriverStep(
                                    HomeGuideSteps.createLivelearningGuideSteps(
                                        "#step222",
                                        "二维码放大",
                                        "1、点击【关闭】，关闭二维码 <br> 2、点击【放大】按比例放大二维码",
                                        HudGlobal.getVue().$driverConstants.positionTypeRightCenter,
                                        222,
                                        222,
                                        true,
                                        false,
                                        that.isHasLastStep(dataList, guide),
                                        ConstantsGuideCode.videoPlayerGuideCodes
                                    )
                                );
                            }
                        }
                    }
                    TBDriver.startDriver();
                });
            }
        },
        /**
         * 抽验检测引导
         */
        checkSignSpotOperationGuide() {
            if (this.isFristEntry) {
                this.isFristEntry = false;
                const that = this;
                GuideStepHelper.loadOperationGuideData(ConstantsGuideCode.videoPlayerSignOutGuideCodes, function(dataList) {
                    if (dataList == null || dataList.length == 0) {
                        return;
                    }
                    for (let i = 0; i < dataList.length; i++) {
                        const guide = dataList[i];
                        //是否已完成引导(0:未完成,1:已完成)
                        //
                        if (guide.guideDone == 0) {
                            const keyCode = guide.guideCode;
                            if (keyCode == "step223") {
                                TBDriver.addDriverStep(
                                    HomeGuideSteps.createLivelearningGuideSteps(
                                        "#step223",
                                        "学员签退",
                                        "若学员学时达到要求，点击【签退】跳转到签退页面",
                                        null,
                                        223,
                                        223,
                                        false,
                                        true,
                                        false,
                                        ConstantsGuideCode.videoPlayerSignOutGuideCodes
                                    )
                                );
                            }
                            if (keyCode == "step224") {
                                TBDriver.addDriverStep(
                                    HomeGuideSteps.createLivelearningGuideSteps(
                                        "#step224",
                                        "培训记录",
                                        "查看学员培训情况",
                                        null,
                                        224,
                                        224,
                                        true,
                                        false,
                                        that.isHasLastStep(dataList, guide),
                                        ConstantsGuideCode.videoPlayerSignOutGuideCodes
                                    )
                                );
                            }
                        }
                    }
                    TBDriver.startDriver();
                });
            }
        },
        /**
         * 是有具有上一步 - lzb 20200903
         */
        isHasLastStep(dataList, codeItem) {
            return true;
            //   if (!dataList || dataList.length == 0) {
            //     return false;
            //   }
            //   if (!codeItem) {
            //     return false;
            //   }

            //   const index = dataList.indexOf(codeItem);
            //   const computeList = dataList.filter((item, idx) => {
            //     return idx < index;
            //   });
            //   if (!computeList || computeList.length == 0) {
            //     return false;
            //   }
            //   for (let i = 0; i < computeList.length; i++) {
            //     const item = computeList[i];
            //     if (item.guideDone == 0) {
            //       return true;
            //     }
            //   }
            //   return false;
        },
        /**
         * 获取鼠标hover事件 2020 -09 -10 lzb 新增
         */
        getMouseHover(item) {
            // console.log("getMouseHover",item)
            let result = "";
            // if (item.description) {
            //     result = item.description;
            // } else {
            result = filters.signSourceFilter(item.signSource) + filters.resultTypeFilter(item.resultType) + this.signType.signTypeName;
            // }
            return result;
        },

        // =========================V1.0.23 ============
        /**
         * 获取鼠标离开中心弹窗出现点击确定按钮  2022-11-29
         */
        handleDialogCenterVisible() {
            console.log("handleDialogCenterVisible rqm");
            this.dialogCenterVisible = false;
            this.dialogCenterJustDisappear = true; 
        },

        // end methods
    },
};
