import hoursApi from "../../../../api/hours/hoursApi";
import Constants from "../../../../util/constants/Constants";
import TrainFormatterHelper from "../../../../util/formatter/TrainFormatterHelper";

export default {
    name: "Tabel",
    props: {
        propOfflineRecordId: String,
    },
    data() {
        return {
            Constants,
            tableData: [],
            requestParam: {
                currentPage: 1,
                pageSize: 10,
                offlineRecordId: "",
            },
            tableObject: {
                responsePageSize: 1,
                responseTotal: 0,
            },
            otherObject: {
                timer: null, //定时器名称
            },
        };
    },
    watch: {
        propOfflineRecordId: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.requestParam.offlineRecordId = newVal;
                    this.handleCurrentChange(this.requestParam.currentPage);
                }
            },
        },
    },
    mounted() {
        /**
         * 定时任务
         */
        this.otherObject.timer = setInterval(() => {
            this.handleCurrentChange(this.requestParam.currentPage);
        }, 1000 * 30);
    },
    methods: {
        /**
         * 签退列表数据请求
         */
        loadListData() {
            if (!this.requestParam.offlineRecordId) {
                return false;
            }
            hoursApi.signinList(this.requestParam).then((response) => {
                const { success, data, total, pageSize, currentPage } = response;
                if (success) {
                    this.tableData = data;
                    this.tableObject.responsePageSize = pageSize;
                    this.tableObject.responseTotal = total;
                    this.requestParam.currentPage = currentPage;
                } else {
                    this.tableData = [];
                    this.requestParam.currentPage = 1;
                    this.requestParam.pageSize = 10;
                    this.tableObject.responsePageSize = 1;
                    this.tableObject.responseTotal = 0;
                }
            });
        },
        /**
         * 分页查询
         * @param {*} val
         */
        handleSizeChange(val) {
            // 每页条数改变
            this.requestParam.pageSize = val;
            this.requestParam.currentPage = 1;
            // 加载数据
            this.loadListData();
        },
        /**
         * 当前页查询
         * @param {*} val
         */
        handleCurrentChange(val = 1) {
            this.requestParam.currentPage = val;

            this.loadListData();
        },
        /**
         * 要求学时
         * @param {*} val
         */
        totalHours(val) {
            return TrainFormatterHelper.missToMinuteStr(val.totalHours);
        },
        /**
         * 本次学习时长
         * @param {*} val
         */
        finishedHours(val) {
            return TrainFormatterHelper.missToMinuteStr(val.finishedHours);
        },
        /**
         * 已完成总学时
         * @param {*} val
         */
        finishedTotalHours(val) {
            return TrainFormatterHelper.missToMinuteStr(val.finishedTotalHours);
        },
    },
    /**
     * 销毁定时器时间
     */
    destroyed() {
        clearInterval(this.otherObject.timer); // 清除定时器
        this.otherObject.timer = null;
    },
};
