export default {
    name: "BaseDialog",
    props:{
        /**
         *   标题  默认提示
         * */
        title:{
            type:String,
            default: "温馨提示",
        },
        /**
         * 是否显示 默认 false
         */
        isVisible:{
            type:Boolean,
            default: false,
        },
        /**
         * 宽度 默认是 420px
         */
        width:{
            type: String,
            default: '420px'
        },
        /**
         * 是否显示关闭按钮, 默认显示
         */
        showClose:{
            type: Boolean,
            default: false,
        },
        /**
         *  是否支持mask点击
         * */
        supportMaskClick:{
            type: Boolean,
            default: true,
        },

        /**
         *  是否包含取消按钮, 默认是包含
         * */
        isContianerCancel:{
            type: Boolean,
            default: true,
        },
        /**
         * 显示文字内容
         * */
        content:{
            type: String,
            default: "",
        },
        /**
         *
         */
        htmlContent:{
            type: String,
            default: "",
        },
        /**
         *   确定按钮文字
         * */
        ensureButtonTitle:{
            type: String,
            default: "确定",
        },
        /**
         *   取消按钮文字
         * */
        cancelButtonTitle:{
            type: String,
            default: "取消",
        },
        /**
         *  描述内容
         * */
        desc:{
            type: String,
            default: "",
        },
        /**
         *
         *  tag 标记是那个弹框 唯一标识
         * */
        tag:{
            type: [String, Number],
            default: null,
        },
        /**
         *   按钮位置是否取反
         * */
        buttonPositionReverse:{
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            inVisible: this.isVisible,
        }
    },
    methods:{
        /**
         * 关闭弹框
         */
        closeDialogEvent(){
            this.close();
        },

        /**
         *  点击确定按钮
         */
        onClickEnsureEvent(){
            this.close();
            this.$emit('call-back',{type:1, tag: this.tag});


        },
        /**
         * 取消弹框
         */
        onClickCancleEvent(){
            this.close();
            this.$emit('call-back',{type:0, tag: this.tag});

        },
        /**
         * 关闭
         */
        close(){
            this.$emit('update:isVisible');
        }

    },
    watch:{
        isVisible:{
            immediate: true,
            handler(val){
                this.inVisible = val;
            }
        }
    }
}
