import SpeechTTS from "@tibi/tb-tts";

export default {
     /**
      * @description: 播放语音
      * @param {*} text
      * @return {*}
      */       
     speakingText(text){
        try {
          const speech = new SpeechTTS(text);
          speech.speak(text);
        }catch(e){
          console.log(`语音播放失败--${e}`);
        }
     }
}
