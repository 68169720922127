import { render, staticRenderFns } from "./SettingModal.vue?vue&type=template&id=3095b7ec"
import script from "./SettingModal.js?vue&type=script&lang=js&external"
export * from "./SettingModal.js?vue&type=script&lang=js&external"
import style0 from "./SettingModal.vue?vue&type=style&index=0&id=3095b7ec&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jenkins_workspace/workspace/vuejs-tb-train-ontraining-web-sim/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3095b7ec')) {
      api.createRecord('3095b7ec', component.options)
    } else {
      api.reload('3095b7ec', component.options)
    }
    module.hot.accept("./SettingModal.vue?vue&type=template&id=3095b7ec", function () {
      api.rerender('3095b7ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/video-training/components/setting-modal/SettingModal.vue"
export default component.exports