import { mapActions, mapGetters } from 'vuex';
import Constants from "@util/constants/Constants";

export default {
    name: 'SettingModal',
    data () {
        return {
            Constants,
            // 设置的默认值放在vuex里的
            checkList: [], // 内容项
            checkErrorList: [], // 内容项状态
            transparency: 0, // 透明度
            fontSize: 0, // 字号
            baberrageRows: 0 // 显示区域
        }
    },
    watch: {
        barrageType: {
            immediate: true,
            handler (newValue, oldValue) {
                if (newValue !== oldValue && newValue) {
                    this.checkList = newValue
                }
            }
        },
        errorType: {
            immediate: true,
            handler (newValue, oldValue) {
                if (newValue !== oldValue && newValue) {
                    this.checkErrorList = newValue
                }
            }
        },
        _transparency: {
            immediate: true,
            handler (newValue, oldValue) {
                if (newValue !== oldValue && newValue) {
                    this.transparency = newValue
                }
            }
        },
        _fontSize: {
            immediate: true,
            handler (newValue, oldValue) {
                if (newValue !== oldValue && newValue) {
                    this.fontSize = newValue;

                }
            }
        },
        _baberrageRows: {
            immediate: true,
            handler (newValue, oldValue) {
                if (newValue !== oldValue && newValue) {
                    this.baberrageRows = newValue
                }
            }
        },
    },
    computed: {
        // mapGetters的别名不能和data名称相同
        ...mapGetters({
            barrageType: 'barrageType',
            errorType: 'errorType',
            _transparency: 'transparency',
            _fontSize: 'fontSize',
            _baberrageRows: 'baberrageRows'
        })
    },
    methods: {
        ...mapActions(['setBarrageType', 'setErrorType', 'setTransparency', 'setFontSize', 'setBaberrageRows']),

        // 滑动条格式化
        formatTooltip () {
            return parseFloat(this.transparency / 10);
        }
    }
}