var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      [
        _c("div", { staticClass: "demonstration" }, [_vm._v("内容项")]),
        _c(
          "el-checkbox-group",
          {
            staticClass: "auto-height",
            on: {
              change: function($event) {
                return _vm.setBarrageType(_vm.checkList)
              }
            },
            model: {
              value: _vm.checkList,
              callback: function($$v) {
                _vm.checkList = $$v
              },
              expression: "checkList"
            }
          },
          [
            _c(
              "el-checkbox",
              { attrs: { label: _vm.Constants.barrageType.SignIn } },
              [_vm._v("签到情况")]
            ),
            _c(
              "el-checkbox",
              { attrs: { label: _vm.Constants.barrageType.SpotCheck } },
              [_vm._v("抽验情况")]
            ),
            _c(
              "el-checkbox",
              { attrs: { label: _vm.Constants.barrageType.SignOut } },
              [_vm._v("签退情况")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("div", { staticClass: "demonstration" }, [_vm._v("内容项状态")]),
        _c(
          "el-checkbox-group",
          {
            on: {
              change: function($event) {
                return _vm.setErrorType(_vm.checkErrorList)
              }
            },
            model: {
              value: _vm.checkErrorList,
              callback: function($$v) {
                _vm.checkErrorList = $$v
              },
              expression: "checkErrorList"
            }
          },
          [
            _c(
              "el-checkbox",
              { attrs: { label: _vm.Constants.barrageType.Stay } },
              [_vm._v("正常")]
            ),
            _c(
              "el-checkbox",
              { attrs: { label: _vm.Constants.barrageType.Error } },
              [_vm._v("异常")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("div", { staticClass: "demonstration" }, [
          _vm._v("透明度" + _vm._s(_vm.transparency))
        ]),
        _c("el-slider", {
          attrs: {
            "show-tooltip": false,
            min: 0,
            max: 10,
            "format-tooltip": _vm.formatTooltip
          },
          on: {
            change: function($event) {
              return _vm.setTransparency(_vm.transparency)
            }
          },
          model: {
            value: _vm.transparency,
            callback: function($$v) {
              _vm.transparency = $$v
            },
            expression: "transparency"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("div", { staticClass: "demonstration" }, [
          _vm._v("字号" + _vm._s(_vm.fontSize))
        ]),
        _c("el-slider", {
          attrs: { "show-tooltip": false, min: 1, max: 2, step: 0.5 },
          on: {
            change: function($event) {
              return _vm.setFontSize(_vm.fontSize)
            }
          },
          model: {
            value: _vm.fontSize,
            callback: function($$v) {
              _vm.fontSize = $$v
            },
            expression: "fontSize"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("div", { staticClass: "demonstration" }, [
          _vm._v("显示区域" + _vm._s(_vm.baberrageRows))
        ]),
        _c("el-slider", {
          attrs: { "show-tooltip": false, min: 1, max: 10 },
          on: {
            change: function($event) {
              return _vm.setBaberrageRows(_vm.baberrageRows)
            }
          },
          model: {
            value: _vm.baberrageRows,
            callback: function($$v) {
              _vm.baberrageRows = $$v
            },
            expression: "baberrageRows"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }