/**
 * socket心跳
 */
export default {
    /**
     *  初始化 心跳 5s 发送一次心跳    30s + 5s 超时检查一次
     * @param opts
     */
     initOptions(opts){
         this.pingInterval = opts ? opts.pingInterval: 5000;
         this.pingTimeout = opts ? opts.pingTimeout: 30000;
         // this.pingIntervalList  = [];
          // set on heartbeat
         this.stopPing();
         this.stopPingTimeOutTimer();
     },
    /**
     * 开始心跳
     */
    startPing(pingBack, timeOutBack){
        const self = this;
        // 先执行一次
        if(pingBack){
            pingBack();
            // 开启超时检查
            this.startPingTimeOut(timeOutBack);
        }
        this.stopPing();
        // 定时执行
        this.pingIntervalTimer = setInterval(function () {
              if(pingBack){
                  pingBack();
              }
            // 开启超时检查
            self.startPingTimeOut(timeOutBack);

        }, this.pingInterval);
        // this.pingIntervalList.push(this.pingIntervalTimer);
    },
    /**
     * 停止心跳
     */
    stopPing(){
        clearInterval(this.pingIntervalTimer);
        this.pingIntervalTimer = null;
        // if(this.pingIntervalList && this.pingIntervalList.length > 0){
        //     for (let i = 0; i < this.pingIntervalList.length; i++) {
        //         let timer = this.pingIntervalList[i];
        //         clearInterval(timer);
        //         timer = null;
        //     }
        // }
        // this.pingIntervalList = [];

    },

    /**
     * 开启超时检查
     */
    startPingTimeOut(timeOutBack){
        if(this.pingTimeoutTimer){
            return;
        }
        const timeOutInterVal = this.pingInterval + this.pingTimeout;
        this.pingTimeoutTimer = setInterval(function () {
            if(timeOutBack){
                timeOutBack();
            }
        },  timeOutInterVal);

    },
    /**
     * 停止超时检查
     */
    stopPingTimeOutTimer(){
        clearInterval(this.pingTimeoutTimer);
        this.pingTimeoutTimer = null;

    },


}
