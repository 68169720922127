import TibiMatomoManger from "../../../util/manager/TibiMatomoManger";
import TrackConstants from "../../../util/constants/TrackConstants";

export default {
    data () {
        return {
            closeQRImg: require('@assets/img/closeQR.png'),
            enlargeQRImg: require('@assets/img/enlargeQR.png'),
            shrinkQRImg: require('@assets/img/shrinkQR.png'),
            enlargeQR: false, // 放大二维码
            enlargeQR_div: false, // 放大二维码下得div是否显示隐藏
            scale: 1.5, // 放大二维码基础尺寸
        }
    },
    watch:{
        isFullScreenType:{
            immediate: true,
            handler(value){
                if(!value && this.$refs.enlargeQR){
                    this.$refs.enlargeQR.style.transform = 'scale(1.5)';
                    this.scale = 1.5
                }
            }
        }
    },
    methods: {
        /**
         * 二维码放大
         */
        enlargeQrcodeSign () {
            this.enlargeQR = true;
            this.enlargeQR_div = true;
        },
        /**
         * 放大二维码关闭
         */
        closeQrcodeSign_enlarge () {
            TibiMatomoManger.sendVideoTrainingTrackEvent(TrackConstants.eventNameKeys.video_training_menu_close_qrcode_sign);
            this.scrollbarAction();
            this.enlargeQR_div = false;
        },
        /**
         * 放大二维码的鼠标滚动放大
         * @param {*} e 
         */
        onmousewheelQrCode (e) {
            const {changeSize,max} = this.formmatScreen();
            let scale = parseFloat(e.currentTarget.style.transform.split('(')[1].split(')')[0] || 1.5);
            if (event.wheelDelta > 0) { // 放大
                scale +=changeSize;
            } else { // 缩小
                scale -= changeSize;
            }
            if (scale >= 1.5 && scale <= max) {
                e.currentTarget.style.transform = `scale(${scale})`;
            }
            return false;
        },
        /**
         * 适配分辨率
         */
        formmatScreen(){
            let qrCodeOption = {
                changeSize:0.25,
                max:3.25
            };
            if(!this.isFullScreenType){
                qrCodeOption.max = 1.85;
                qrCodeOption.changeSize = 0.15
                return qrCodeOption;
            }else{
                if(window.screen.width >1680){
                    qrCodeOption.max=3.5
                }else  if(window.screen.width >1440 && window.screen.width <=1680){
                    qrCodeOption.max=3.25
                }else  if(window.screen.width >1366 && window.screen.width <=1440){
                    qrCodeOption.max=2.9
                    qrCodeOption.changeSize = 0.2
                } if(window.screen.width >1280 && window.screen.width <=1366){
                    qrCodeOption.max=2.25
                    qrCodeOption.changeSize = 0.15
                }
                return qrCodeOption;
            }
           
        },
        /**
         * 鼠标放在放大后的二维码上 禁止界面滚动条滚动 （处理的是鼠标滚动后界面跟着滚）
         */
        scrollbarStop () {
            document.getElementsByClassName('el-scrollbar__wrap')[0].style.overflowY = "hidden";
            document.getElementsByClassName('el-scrollbar__wrap')[0].style.marginRight = "0";
        },
        /**
         * 鼠标离开在放大后的二维码上 恢复界面滚动条滚动
         */
        scrollbarAction () {
            document.getElementsByClassName('el-scrollbar__wrap')[0].style.overflowY = "scroll";
            document.getElementsByClassName('el-scrollbar__wrap')[0].style.marginRight = "-17px";
        },
        /**
         * 缩小放大后的二维码
         */
        narrowQrcodeSign_enlarge () {
            const {changeSize} = this.formmatScreen();
            this.scale -= changeSize
            if (this.scale >= 1.5) {
                this.$refs.enlargeQR.style.transform = `scale(${this.scale})`;
            } else {
                this.scale += changeSize
            }
        },
        /**
         * 放大放大后的二维码
         */
        enlargeQrcodeSign_enlarge () {
            const {changeSize,max} = this.formmatScreen();
            this.scale += changeSize
            if (this.scale >= 1.5 && this.scale <= max) {
                this.$refs.enlargeQR.style.transform = `scale(${this.scale})`;
            } else {
                this.scale -= changeSize
            }
        }
    }
}