import { SYSTEM_TYPE } from "../../../util/constants/EnumConstants";
import { getCurrentSystemType } from "../../../util/user/UserManger";
import TrackConstants from "../../../util/constants/TrackConstants";
import TibiMatomoManger from "../../../util/manager/TibiMatomoManger";

export default {
    data() {
        return {
            isPut: true, // 视频菜单是否收起
            isOpen: false, // 视频菜单是否展开 防止闪屏 设置了2个变量分别加载样式
            settingImg: require("@assets/img/setting.png"),
            isFullScreenType: false, //是否全屏模式
            meetingObject: {
                // 会议对象
                selectType: 0, // 0  会议简介 1 视频  2 文档  3 图片
            },
        };
    },
    computed: {
        /**
         * 是否会现场会议
         */
        isOfflineMeeting() {
            return getCurrentSystemType() == SYSTEM_TYPE.MEETING;
        },

        /**
         * 是否是视频播放类型
         */
        isVideoPlayType() {
            // 不是会议类型或者会议类型中的视频类型
            if (this.isOfflineMeeting) {
                if (this.meetingObject.selectType == 1) return true;
                else return false;
            } else {
                return true;
            }
        },
        getTestObj() {
            // 生成一个包含10、20和30的数组
            const options = [10, 20, 30];

            // 从数组中随机选择一个元素
            const randomValue = options[Math.floor(Math.random() * options.length)];
            return {
                barrageType: randomValue,
                barrageState: 1,
                msg: "秦冰雪签到成功",
            };
        },
    },
    methods: {
        /**
         * 左侧菜单操作
         */
        putMenu() {
            this.isPut = !this.isPut;
            this.isOpen = !this.isPut;
            let name = TrackConstants.eventNameKeys.video_training_menu_put_menu_open;
            if (!this.isPut) {
                name = TrackConstants.eventNameKeys.video_training_menu_put_menu_retract;
            }
            TibiMatomoManger.sendVideoTrainingTrackEvent(name);
        },
        /**
         * 是否是现场视频播放
         */
        isOfflineVideo() {
            if (this.isOfflineMeeting && this.meetingObject.selectType != 1) {
                return false;
            }
            return true;
        },

        /**
         * 寻找父级组件
         */
        getParentMountedDom() {
            if (this.isOfflineMeeting) {
                return "#right-page";
            } else {
                return "#player-container-id";
            }
        },
        /**
         * 寻找弹幕父级组件
         */
        getBaberRageParentMountedDom() {
            if (this.isOfflineMeeting) {
                return "#videoOutBox";
            } else {
                return "#player-container-id";
            }
        },

        /**
         * 获取会议数据源
         */
        getMeetingSourceDataList(data) {
            if (this.meetingObject.selectType == 0) {
                return [];
            }
            if (this.meetingObject.selectType == 1) {
                return data.videoList || [];
            }
            if (this.meetingObject.selectType == 2) {
                return data.docList || [];
            }
            if (this.meetingObject.selectType == 3) {
                return data.picList || [];
            }
        },
        /**
         * 获取上一个课件
         */
        getLastCourseMeetingList(data, meetingCourseId) {
            if (this.meetingObject.selectType == 1) {
                return data.videoList.find((item) => item.courseId == meetingCourseId);
            }
            if (this.meetingObject.selectType == 2) {
                return data.docList.find((item) => item.courseId == meetingCourseId);
            }
            if (this.meetingObject.selectType == 3) {
                return data.picList.find((item) => item.courseId == meetingCourseId);
            }
            return null;
        },
        /**
         * 初始化会议内容列表
         */
        initMeetingContentStateList(data) {
            if (data.videoList && data.videoList.length > 0) {
                data.videoList.map((item) => {
                    this.$set(item, "checked", false);
                });
            }
            if (data.docList && data.docList.length > 0) {
                data.docList.map((item) => {
                    this.$set(item, "checked", false);
                });
            }
            if (data.picList && data.picList.length > 0) {
                data.picList.map((item) => {
                    this.$set(item, "checked", false);
                });
            }
        },

        /**
         *  刷新列表的状态
         */
        refreshMeetingContentStateList(item, dataList) {
            dataList.forEach((element) => {
                element.checked = false;
                if (element.courseId == item.courseId) {
                    element.checked = true;
                    item.checked = true;
                }
            });
            console.log("列表的状态====", dataList);
        },
    },
};
