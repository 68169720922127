import { mapGetters, mapActions } from "vuex";
import Constants from "@util/constants/Constants";
import UUID from "uuidjs";
import { MESSAGE_TYPE } from "vue-play-baberrage";
import TrackConstants from "../../../util/constants/TrackConstants";
import TibiMatomoManger from "../../../util/manager/TibiMatomoManger";

export default {
    data() {
        return {
            isPut: true, // 视频菜单是否收起
            isOpen: false, // 视频菜单是否展开 防止闪屏 设置了2个变量分别加载样式
            settingImg: require("@assets/img/setting.png"),
            isShowSetting: false, //是否显示设置弹窗
            barrageIsShow: true, // 弹幕是否显示
            barrageLoop: false, // 弹幕是否循环
            lanesCount: 4, // 泳道数量
            hoverLanePause: true, // 触摸是否暂停 (还未实现)
            barrageList: [], // 弹幕集合
            randomNumber: 35, // 弹幕速度的随机范围30-60
            avatarImg: {
                avatarSignIn: require("@assets/img/barrageImg/SignIn.png"),
                avatarCustom: require("@assets/img/barrageImg/Custom.png"),
                avatarSignOut: require("@assets/img/barrageImg/SignOut.png"),
                avatarSpotCheck: require("@assets/img/barrageImg/SpotCheck.png"),
                avatarError: require("@assets/img/barrageImg/Error.png"),
                avatarStay: require("@assets/img/barrageImg/Stay.png"),
            },
            interval: null,
        };
    },
    watch: {
        // 行数
        // _baberrageRows: {
        //     immediate: true,
        //     handler(newValue, oldValue) {
        //         if (newValue !== oldValue) {
        //             this.lanesCount = newValue;
        //         }
        //     },
        // },
        // 是否显示弹幕
        _barrageIsShow: {
            immediate: true,
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.barrageIsShow = newValue;
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            barrageType: "barrageType",
            errorType: "errorType",
            _transparency: "transparency",
            _fontSize: "fontSize",
            _baberrageRows: "baberrageRows",
            _barrageIsShow: "barrageIsShow",
        }),
    },
    methods: {
        ...mapActions(["setBarrageIsShow", "setBarrageType", "setErrorType", "setTransparency", "setFontSize", "setBaberrageRows"]),
        /**
         * 左侧菜单操作
         */
        putMenu() {
            this.isPut = !this.isPut;
            this.isOpen = !this.isPut;
            let name = TrackConstants.eventNameKeys.video_training_menu_put_menu_open;
            if (!this.isPut) {
                name = TrackConstants.eventNameKeys.video_training_menu_put_menu_retract;
            }
            TibiMatomoManger.sendVideoTrainingTrackEvent(name);
        },
        settingClick() {
            this.isShowSetting = !this.isShowSetting;
        },
        settingMouse() {
            this.isShowSetting = false;
        },
        /**
         * 弹幕数据处理
         * @param {*} data
         */
        addBaberRageList(data) {
            /**
             * 弹幕类型
             * 弹幕类型，10：签到，20：抽验(正常：待抽验/抽验成功，异常：连续多次未抽验，系统签退/比对失败),21 待抽验(算正常情况)，
             * 22：过期系统签退（异常情况）,30：签退，100：自定义
             * 内容项状态（0:异常，1：正常)
             */
            let { barrageType, msg, barrageState } = data;

            // 弹幕头像图片需要在过滤数据前处理
            let avatar = this.setAvatar(barrageType);
            // 21 待抽验 和22 过期系统签退 属于抽验的特殊情况 如果后台有推送21和22 一律转为20当抽验 进行处理判断
            if (barrageType === 21 || barrageType === 22) {
                barrageType = 20;
            }
            // 内容项设置 和 内容项状态设置  过滤是否有被勾选的类型弹幕弹幕
            if (!this.barrageType.includes(barrageType) || !this.errorType.includes(barrageState)) {
                return;
            }
            const that = this;
            /**
             * 推送弹幕
             */
            that.barrageList.push({
                id: UUID.generate(),
                msg,
                avatar,
                time: 25,
                barrageStyle: {
                    margin: "20px",
                },
                extraWidth: 80,
                type: MESSAGE_TYPE.NORMAL,
            });

            // 不用定时更新

            // timeOut && clearTimeout(timeOut);
            // // 弹幕推送出来是延迟进行的 需要缓冲再获取deom进行相关操作
            // let timeOut = setTimeout(() => {
            //     this.$nextTick(() => {
            //         Array.prototype.map.call(document.querySelectorAll(".baberrage-item"), (item) => {
            //             item.style.opacity = `${this._transparency - 0.5}`;
            //         });
            //         [...document.querySelectorAll(".normal")].map((item) => {
            //             item.style.transform = `scale(${this._fontSize})`;
            //         });
            //     });
            // }, 2000);
            // 每秒轮询一次（弹幕推送是延迟的）
            // let interval = setInterval(() => {
            //     //如果有弹幕推送出来进行设置
            //     if (document.querySelectorAll(".baberrage-item").length > 0) {
            //         // 节流
            //         if (!interval) {
            //             clearInterval(interval);
            //         }
            //         // 设置弹幕样式
            //         this.$nextTick(() => {
            //             Array.prototype.map.call(document.querySelectorAll(".baberrage-item"), (item) => {
            //                 item.style.opacity = `${this._transparency - 0.5}`;
            //             });
            //             [...document.querySelectorAll(".normal")].map((item) => {
            //                 item.style.transform = `scale(${this._fontSize})`;
            //             });
            //         });
            //     } else {
            //         // 没有推送清除计时器
            //         interval && clearInterval(interval);
            //     }
            // }, 1000);
        },
        /**
         * 点击视频界面可以控制播放和暂停(原视频组件支持此功能,弹幕层遮挡导致单独处理)
         */
        playVideo() {
            if (this.playerObject && this.playerObject.playing) {
                this.pause(); // 暂停
            } else {
                this.play(); // 播放
            }
        },
        /**
         * 随机数处理
         */
        randomSpee() {
            return Math.floor(Math.random() * (this.randomNumber - 60) + 60);
        },
        /**
         * 弹幕图标设置
         * @param {*} barrageType
         */
        setAvatar(barrageType) {
            if (barrageType === Constants.barrageType.SignIn) {
                // 签到
                return this.avatarImg.avatarSignIn;
            } else if (barrageType === Constants.barrageType.SpotCheck) {
                // 抽验
                return this.avatarImg.avatarSpotCheck;
            } else if (barrageType === Constants.barrageType.SignOut) {
                // 签出
                return this.avatarImg.avatarSignOut;
            } else if (barrageType === Constants.barrageType.Custom) {
                // 自定义
                return this.avatarImg.avatarCustom;
            } else if (barrageType === Constants.barrageType.SpotCheckError) {
                // 过期系统签退
                return this.avatarImg.avatarError;
            } else if (barrageType === Constants.barrageType.SpotCheckStay) {
                // 待抽验
                return this.avatarImg.avatarStay;
            }
        },
    },
};
