var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.contentLoading,
          expression: "contentLoading"
        }
      ],
      staticClass: "pdf-preview-wrapper"
    },
    [
      _c("div", { staticClass: "p-hd-bar" }, [
        _c("div", { staticClass: "hd-left-tip" }, [
          _vm._v("PDF预览只适用于部分浏览器,如果显示不全,可以"),
          _c(
            "span",
            { staticClass: "down-tip", on: { click: _vm.onClickDown } },
            [_vm._v("下载")]
          ),
          _vm._v("查看")
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.numPages > 1,
                expression: "numPages > 1"
              }
            ],
            staticClass: "hd-center-group"
          },
          [
            _c(
              "el-button",
              {
                attrs: { disabled: _vm.currentPage == 1, type: "text" },
                on: { click: _vm.onLastPageEvent }
              },
              [_vm._v("上一页")]
            ),
            _c("span", { staticClass: "hd-center-number" }, [
              _c("span", [_vm._v("第" + _vm._s(_vm.currentPage) + "页，")]),
              _c("span", [_vm._v("共" + _vm._s(_vm.numPages) + "页")])
            ]),
            _c(
              "el-button",
              {
                attrs: {
                  disabled: _vm.currentPage == _vm.numPages,
                  type: "text"
                },
                on: { click: _vm.onNextPageEvent }
              },
              [_vm._v("下一页")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "hd-right-group" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.onClickDown }
              },
              [_vm._v("下载")]
            )
          ],
          1
        )
      ]),
      _c("pdf", {
        ref: "pdfRef",
        staticClass: "p-bd-content",
        attrs: { src: _vm.pdfURL, page: _vm.currentPage },
        on: {
          "num-pages": function($event) {
            _vm.numPages = $event
          },
          "page-loaded": function($event) {
            _vm.currentPage = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }