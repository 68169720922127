import { pdf, CMapReaderFactory } from "@tibi/vue-pdf";
import GlobalHelper from "../../../../util/GlobalHelper";
export default {
    name: "PdfPreview",
    components: {
        pdf,
    },
    props: {
        url: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            currentPage: 1,
            numPages: 1,
            pdfURL: "",
            sourceUrl: "",
            contentLoading: false,
        };
    },
    mounted() {},
    activated() {},
    destroyed() {
        this.$refs.pdfRef && this.$refs.pdfRef.beware();
    },
    watch: {
        url: {
            immediate: true,
            handler(val) {
                if (val && val.length > 0) {
                    this.fetchPDF(val);
                }
            },
        },
    },
    methods: {
        /**
         * 加载pdf
         */
        fetchPDF(queryUrl) {
            if (this.sourceUrl === queryUrl) {
                return;
            }
            this.sourceUrl = queryUrl;
            const that = this;
            this.contentLoading = true;
            that.pdfURL = pdf.createLoadingTask({ url: queryUrl, cMapUrl: CMapReaderFactory, cMapPacked: true });
            setTimeout(() => {
                that.pdfURL.promise
                    .then((pdfThen) => {
                        that.numPages = pdfThen.numPages;
                        that.contentLoading = false;
                        // eslint-disable-next-line no-underscore-dangle
                        that.pdfURL._worker.destroy();
                    })
                    .catch((error) => {
                        console.log("预览失败，可下载查看", error);
                        that.contentLoading = false;
                    });
            }, 0);
        },

        /**
         *  PDF 上一页 lzb 2021-06-15
         * */
        onLastPageEvent() {
            if (this.currentPage > 1) {
                // eslint-disable-next-line no-plusplus
                this.currentPage--;
            }
        },
        /**
         *   PDF 下一页 lzb 2021-06-05
         * */
        onNextPageEvent() {
            if (this.currentPage < this.numPages) {
                // eslint-disable-next-line no-plusplus
                this.currentPage++;
            }
        },
        /**
         * @description: 下载
         * @return {*}
         */

        onClickDown() {
            GlobalHelper.openWithUrl(this.sourceUrl);
        },

        /**
         * @description: 进入
         * @return {*}
         */
        monitorMouseEnter() {
            console.log("---进入--");
        },
        /**
         * @description: 离开
         * @return {*}
         */
        monitorMouseLeave() {
            console.log("---离开--");
        },
    },
};
